import i18n from 'i18next';
import { call, put, select } from 'redux-saga/effects';
import config from '../../config';
import { IAuction, IItem, IUser } from '../../models';
import { appActions, dashboardActions, itemActions } from '../../redux/actions';
import { ItemSelectors, UserSelectors } from '../../redux/selectors';
import { api } from '../../services/api';
import {
  GetFavoritesAllResponse,
  GetFavoritesIdsResponse,
  GetFavoritesResponse,
  SetFavoritesResponse,
} from '../../services/api/response.types';

// Favorites
export function* getFavorites(action: ReturnType<typeof itemActions.getFavorites>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/item/favorites.getFavorites without logged in?');
    return;
  }

  const pageSize = config.ITEMS_LISTING_PERPAGE;
  const page = action.payload;
  const response: GetFavoritesResponse = yield call(api.getFavorites, {
    page,
    perpage: pageSize,
  });
  if (response.ok && response.data && response.headers) {
    const total = response.headers['data-total-records'];

    yield put(itemActions.getFavoritesSuccess(response.data));
    yield put(dashboardActions.setFavoritePagination(page, +total));

    const ebidItems = response.data.filter(
      (item) => item.saleEventType === IAuction.AUCTION_TYPES.STATIC,
    );

    if (ebidItems.length) {
      // TODO: Why do we need to do this???
      const staticItemIds = ebidItems.map((item) => item.itemId);
      yield put(itemActions.getEbidItems(staticItemIds));
    }
  } else {
    yield put(itemActions.getFavoritesFailure('getFavorites error'));
  }
}

export function* getFavoritesAll() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item/favorites.getFavoritesAll without logged in?');
    return;
  }
  const response: GetFavoritesAllResponse = yield call(api.getFavoritesAll);

  if (response.ok && response.data) {
    yield put(itemActions.getFavoritesAllSuccess(response.data));
  } else {
    yield put(itemActions.getFavoritesAllFailure('getFavoritesAll error'));
  }
}

export function* setFavoritesNote(action: ReturnType<typeof itemActions.setFavoritesNote>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/item.setFavoritesNote without logged in?');
    return;
  }

  const { itemId, notes } = action.payload;

  const favorites: IItem.Favorite[] = yield select(ItemSelectors.favoritesAll);
  const favorite = favorites.find((item) => item.itemId === itemId);

  let response: SetFavoritesResponse;

  if (favorite) {
    response = yield call(api.updateFavorites, favorite.itemId, {
      itemId,
      notes,
    });
  } else {
    response = yield call(api.setFavorites, {
      itemId,
      notes,
      favorite: false,
    });
  }

  if (response.ok && response.data) {
    const { notes: responseNote, id } = response.data.item;
    yield put(itemActions.setFavoritesNoteSuccess(id, responseNote || ''));

    yield put(itemActions.getFavoritesAll());
  } else {
    yield put(itemActions.setFavoritesNoteFailure('setFavoritesNote error'));
  }
}

export function* setFavorite(action: ReturnType<typeof itemActions.setFavorite>) {
  const account: IUser.Account = yield select(UserSelectors.account);

  if (!account) {
    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: 'Sorry!',
        message: i18n.t('infos:must_login_favorite'),
      }),
    );
    return;
  }

  const { itemId, favorite, saleEventId } = action.payload;
  const favorites: IItem.Favorite[] = yield select(ItemSelectors.favoritesAll);
  const existingItem = favorites.find((item) => item.itemId === itemId);

  let response: SetFavoritesResponse;
  const data = { itemId, favorite, __saleEventId: saleEventId };

  if (existingItem) {
    response = yield call(api.updateFavorites, existingItem.itemId, data);
  } else {
    response = yield call(api.setFavorites, data);
  }

  if (response.ok && response.data) {
    yield put(itemActions.setFavoriteSuccess(response.data));

    const text = response.data.item.favorite
      ? i18n.t('labels:added_to_watchlist')
      : i18n.t('labels:removed_from_watchlist');

    yield put(
      appActions.showAlert({
        alertType: 'dropdown',
        messageType: 'info',
        title: 'Pickles',
        message: text,
      }),
    );

    yield put(itemActions.getFavoritesAll());
  } else {
    yield put(itemActions.setFavoriteFailure('setFavorite error'));
  }
}

export function* getFavoritesIds() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item/favorites.getFavoritesIds without logged in?');
    return;
  }

  const params = { fields: 'item_id', perpage: 1000 };

  const response: GetFavoritesIdsResponse = yield call(api.getFavorites, params);

  if (response.ok && response.data) {
    yield put(itemActions.getFavoritesIdsSuccess(response.data));
  } else {
    yield put(itemActions.getFavoritesIdsFailure('getFavoritesIds error'));
  }
}

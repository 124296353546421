import { STRAPI_URL } from '@pickles/shared/config/env';

export const getPageNavigationData = async () => {
  const res = await fetch(`${STRAPI_URL}/api/nav`);
  const data: PageResponse = await res.json();
  return data;
};

export type PageResponse = {
  data: {
    id: number;
    attributes: Pages;
  };
};

export type Pages = {
  buy_pages: {
    data: Page[];
  };
  sell_pages: {
    data: Page[];
  };
  services: {
    data: Page[];
  };
  about_us: {
    data: Page[];
  };
  more: {
    data: Page[];
  };
  eois: {
    data: Page[];
  };
};

export type Page = {
  id: number;
  attributes: {
    slug: string;
    title: string;
    basePath: string | null;
  };
};

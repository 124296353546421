import { useSelector as reduxUseSelector } from 'react-redux';
import { IEbid } from '../../models';
import { EbidAuctionSelectors } from './selectors';
import { calculator } from './utils';
import _ from 'lodash';

export const createEbidAuctionHooks = (useSelector: typeof reduxUseSelector) => ({
  useCalculator: () => {
    const ranges = useSelector(EbidAuctionSelectors.increments);
    return ranges && calculator(ranges);
  },

  useItemsByIds: (ids: number[]) => {
    const items = useSelector(EbidAuctionSelectors.items);
    return ids.reduce<IEbid.ItemsMap>((result, id) => ({ ...result, [id]: items[id] }), {});
  },

  useEbidItem: (id: number) => {
    const items = useSelector(EbidAuctionSelectors.items);
    return items[id] || null;
  },

  useBidHistory: (itemId: number) => {
    const bidsHistory = useSelector(EbidAuctionSelectors.history);
    return bidsHistory[itemId];
  },

  useConnectionStatus: () => useSelector(EbidAuctionSelectors.status),

  useTotalBids: (id: number) => {
    const totalBids = useSelector(EbidAuctionSelectors.totalBids);
    return totalBids[id] || 0;
  },

  useItems: () => useSelector(EbidAuctionSelectors.items),

  useCounts: () => useSelector(EbidAuctionSelectors.counts),

  useCountsByEvent: (saleEventId: number) => {
    const counts = useSelector(EbidAuctionSelectors.counts);
    return counts[saleEventId];
  },

  useSocketId: () => {
    let socketId = useSelector(EbidAuctionSelectors.socketId);
    if (socketId === 'none') {
      return socketId;
    }
    const trimmedId = `${socketId.slice(0, 4)}...${socketId.slice(-4)}`;
    return trimmedId;
  },

  useSocketLatency: () => useSelector(EbidAuctionSelectors.socketLatency),
});

import { EventCategory } from './general.types';

export enum AUCTION_STATUS_LABEL {
  LIVE = 'LIVE',
  STATIC = 'STATIC',
  ON_HOLD = 'ON_HOLD',
}
export type AuctionStatusLabel = keyof typeof AUCTION_STATUS_LABEL;
/**
 * Auction Types
 */
export enum AUCTION_TYPES {
  LIVE = 'LIVE',
  STATIC = 'STATIC',
}
export type AuctionTypes = keyof typeof AUCTION_TYPES | null;

export const AUCTION_TYPE_LABEL: { [key in AUCTION_TYPES]: string } = {
  STATIC: 'E-BIDDING ',
  LIVE: 'LIVE',
};

export interface Auction {
  auctionId: number;
  city: string;
  description?: string;
  endAt: string;
  eventType: AuctionTypes;
  eventCategory: EventCategory;
  favsCt: number | null;
  heroImage?: string;
  heroImageLocation?: string;
  id: number;
  itemsCt?: number;
  latitude: number;
  locationName?: string;
  longitude: number;
  name: string;
  phone?: string;
  pos: string;
  posImportantNotes: string;
  postCode?: string;
  prebidsCt: number;
  simulcastLive: number;
  startAt: string;
  state: string;
  viewTime?: string;
  // computed
  isLive?: boolean;
  isLiveStarted?: boolean;
  isEbid?: boolean;
}

import Script from 'next/script';

const TrengoChat = () => {
  {/* Global Site Code - Trengo Chat */}
  return (
    <Script
      id="trengo-chat"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
          window.Trengo = window.Trengo || {};
          window.Trengo.key = 'thf8OeY1lQyuKckvFlC5';
          (function(d, script, t) {
            script = d.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://static.widget.trengo.eu/embed.js';
            d.getElementsByTagName('head')[0].appendChild(script);
          }(document));
        `,
      }}
    />
  );
};

export default TrengoChat;

import Script from 'next/script';

const CrazyEgg = () => {
  {/* Global Site Code - CrazyEgg Heat Map */}
  return (
    <Script
      id="crazy-egg"
      async
      strategy="afterInteractive"
      src="//script.crazyegg.com/pages/scripts/0033/3960.js"
    />
  );
};

export default CrazyEgg;

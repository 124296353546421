import { useSelector as reduxUseSelector } from 'react-redux';
import { WebSelectors } from './selectors';

export const createWebHooks = (useSelector: typeof reduxUseSelector) => ({
  useHeaderLinks: () => useSelector(WebSelectors.headerLinks),

  useRegisterErrorMsg: () => useSelector(WebSelectors.registerError),

  useContactUsForm: () => useSelector(WebSelectors.contactUsForm),

  useSavedPath: () => useSelector(WebSelectors.savedPath),
});

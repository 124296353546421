import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  DashboardResponseData,
  OffersResponseData,
  PurchasesV2ResponseData,
  SearchItemResponse,
} from '../../services/api/response.types';
import { SourceMap } from '../../utils/helpers';
import { AuctionFilterBody, ItemsRequestBody } from '../../services/api/request.types';
import { IAuction, IGeneral, IItem } from '../../models';
import { setComputed } from './utils';
import { userActions } from '../user/slice';

export interface DashboardState {
  auctions: SourceMap<IAuction.Auction>;
  counts: {
    favsCt: number;
    prebidsCt: number;
    purchases: number;
    offers: number;
    mocCt: number;
  };
  pagination: {
    auction: { [auctionId: number]: IGeneral.AuctionPagination };
    search: IGeneral.SearchPagination | null;
    purchase: IGeneral.Pagination | null;
    purchaseV2: {
      [IItem.PurchaseStatus.unpaid]: IGeneral.Pagination | null;
      [IItem.PaymentStatus.paid]: IGeneral.Pagination | null;
    };
    favorite: IGeneral.Pagination | null;
  };
  filters: {
    /**
     * Filter values for auctions screen
     */
    auctions: {
      eventType: string[];
      eventCategory: string[];
    };
    /**
     * Filter values for specific auction event screen
     */
    auction: {
      [auctionId: number]: {
        itemIds: number[];
        available: {
          year: string[];
          make: string[];
          model: string[];
          location: string[];
        };
        selected: {
          year: string[];
          make: string[];
          model: string[];
          location: string[];
        };
      };
    };
    search: {
      available: {
        year: string[];
        make: string[];
        model: string[];
        condition: string[];
        category: string[];
      };
      selected: {
        year: string[];
        make: string[];
        model: string[];
        condition: string[];
        category: string[];
      };
    };
    prebid: {
      make: string[];
      model: string[];
      location: string[];
    };
    ebidding: {
      make: string[];
      model: string[];
      location: string[];
    };
    purchase: {
      make: string[];
      model: string[];
      location: string[];
    };
    purchaseV2: {
      available: {
        type: string[];
        make: string[];
        model: string[];
        location: string[];
      };
      selected: {
        type: string[];
        make: string[];
        model: string[];
        location: string[];
      };
    };
    favorite: {
      make: string[];
      model: string[];
      location: string[];
    };
  };
}
type AuctionSourceMap = SourceMap<IAuction.Auction>;

const initialPurchaseV2FilterState = {
  type: [],
  make: [],
  model: [],
  location: [],
};

const INITIAL_STATE: DashboardState = {
  auctions: {},
  counts: {
    favsCt: 0,
    prebidsCt: 0,
    purchases: 0,
    offers: 0,
    mocCt: 0,
  },
  pagination: {
    auction: {},
    search: null,
    purchase: null,
    purchaseV2: {
      unpaid: null,
      paid: null,
    },
    favorite: null,
  },
  filters: {
    auctions: {
      eventCategory: [],
      eventType: [],
    },
    auction: {},
    search: {
      available: {
        year: [],
        make: [],
        model: [],
        condition: [],
        category: [],
      },
      selected: {
        year: [],
        make: [],
        model: [],
        condition: [],
        category: [],
      },
    },
    prebid: {
      make: [],
      model: [],
      location: [],
    },
    ebidding: {
      make: [],
      model: [],
      location: [],
    },
    purchase: {
      make: [],
      model: [],
      location: [],
    },
    purchaseV2: {
      available: initialPurchaseV2FilterState,
      selected: initialPurchaseV2FilterState,
    },
    favorite: {
      make: [],
      model: [],
      location: [],
    },
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    getDashboardSuccess: {
      reducer: (state, action: PayloadAction<DashboardResponseData>) => {
        const { events, counts } = action.payload;

        // const auctionsMap = arrayItemsToMap<IAuction.Auction>(events);

        events.forEach((auction) => {
          state.auctions[auction.id] = setComputed(auction);
        });

        // Object.values(auctionsMap).forEach((auction: IAuction.Auction) => {

        // });

        state.counts = counts || state.counts;
      },
      prepare: (payload: DashboardResponseData) => ({
        payload,
      }),
    },
    increaseFavoriteCount: {
      reducer: (state, action: PayloadAction<{ saleEventId: number }>) => {
        const { saleEventId } = action.payload;
        const auction = state.auctions[saleEventId];
        if (auction) {
          if (auction.favsCt) {
            auction.favsCt += 1;
          } else {
            auction.favsCt = 1;
          }
        }
        if (state.counts) {
          state.counts.favsCt += 1;
        }
      },
      prepare: (payload: { saleEventId: number }) => ({ payload }),
    },
    decreaseFavoriteCount: {
      reducer: (state, action: PayloadAction<{ saleEventId: number }>) => {
        const { saleEventId } = action.payload;
        const auction = state.auctions[saleEventId];
        if (auction) {
          if (auction.favsCt) {
            auction.favsCt -= 1;
          } else {
            auction.favsCt = 0;
          }
        }
        if (state.counts) {
          state.counts.favsCt -= 1;
        }
      },
      prepare: (payload: { saleEventId: number }) => ({ payload }),
    },
    increasePrebidCount: {
      reducer: (state, action: PayloadAction<{ saleEventId: number }>) => {
        const { saleEventId } = action.payload;
        const auction = state.auctions[saleEventId];
        if (auction) {
          if (auction.prebidsCt) {
            auction.prebidsCt += 1;
          } else {
            auction.prebidsCt = 1;
          }
        }
        if (state.counts) {
          state.counts.prebidsCt += 1;
        }
      },
      prepare: (payload: { saleEventId: number }) => ({ payload }),
    },
    decreasePrebidCount: {
      reducer: (state, action: PayloadAction<{ saleEventId: number }>) => {
        const { saleEventId } = action.payload;
        const auction = state.auctions[saleEventId];
        if (auction) {
          if (auction.prebidsCt) {
            auction.prebidsCt -= 1;
          } else {
            auction.prebidsCt = 0;
          }
        }
        if (state.counts) {
          state.counts.prebidsCt -= 1;
        }
      },
      prepare: (payload: { saleEventId: number }) => ({ payload }),
    },
    setFavoriteSuccess: {
      reducer: (state, action: PayloadAction<{ event: any }>) => {
        const { event } = action.payload;
        if (event) {
          let { id } = event;
          const prevAuction = state.auctions[id];
          if (prevAuction) {
            prevAuction.favsCt = event.favsCt;
          }
        }
      },
      prepare: (payload: { event: any }) => ({
        payload,
      }),
    },
    getAuctionSuccess: {
      reducer: (state, action: PayloadAction<IAuction.Auction>) => {
        const auction = action.payload;
        state.auctions[auction.id] = setComputed(auction);
      },
      prepare: (payload: IAuction.Auction) => ({
        payload,
      }),
    },
    getAuctionsSuccess: {
      reducer: (state, action: PayloadAction<IAuction.Auction[]>) => {
        const auctions = action.payload.map((auction) => setComputed(auction));

        const auctionsObj: AuctionSourceMap = {};
        auctions.forEach((auction) => {
          auctionsObj[auction.id] = auction;
        });

        state.auctions = auctionsObj;
      },
      prepare: (payload: IAuction.Auction[]) => ({
        payload,
      }),
    },
    setAuctionPagination: {
      reducer: (state, action: PayloadAction<IGeneral.AuctionPagination>) => {
        const pagination = action.payload;
        if (pagination) {
          state.pagination.auction[pagination.id] = pagination;
        }
      },
      prepare: (id: number, page: number, total: number) => ({
        payload: { id, page, total },
      }),
    },
    setPurchasePagination: {
      reducer: (state, action: PayloadAction<{ page: number; total: number } | null>) => {
        state.pagination.purchase = action.payload;
      },
      prepare: (page: number, total: number) => ({
        payload: { page, total },
      }),
    },
    setPurchaseV2Pagination: {
      reducer: (
        state,
        action: PayloadAction<{
          page: number;
          total: number;
          status: IItem.PurchaseStatusType;
        } | null>,
      ) => {
        const { status, page, total } = action.payload as {
          page: number;
          total: number;
          status: IItem.PurchaseStatusType;
        };
        state.pagination.purchaseV2[status] = { page, total };
      },
      prepare: (page: number, total: number, status: IItem.PurchaseStatusType) => ({
        payload: { page, total, status },
      }),
    },
    setFavoritePagination: {
      reducer: (state, action: PayloadAction<{ page: number; total: number } | null>) => {
        state.pagination.favorite = action.payload;
      },
      prepare: (page: number, total: number) => ({
        payload: { page, total },
      }),
    },
    setSearchPagination: {
      reducer: (state, action: PayloadAction<{ keyword: string; page: number; total: number }>) => {
        const { keyword, page, total } = action.payload;
        if (state.pagination.search) {
          state.pagination.search.keyword = keyword;
          state.pagination.search.page = page;
          state.pagination.search.total = total;
        } else {
          state.pagination.search = {
            keyword,
            page,
            total,
          };
        }
      },
      prepare: (keyword: string, page: number, total: number) => ({
        payload: { keyword, page, total },
      }),
    },
    getSearchSuggestionsSuccess: {
      reducer: (state, action: PayloadAction<{ keyword: string; suggestions: string[] }>) => {
        const { keyword, suggestions } = action.payload;
        if (state.pagination.search) {
          state.pagination.search.keyword = keyword;
          state.pagination.search.suggestions = suggestions;
        } else {
          state.pagination.search = {
            keyword,
            suggestions,
          };
        }
      },
      prepare: (keyword: string, suggestions: string[]) => ({
        payload: { keyword, suggestions },
      }),
    },
    setAuctionsFilter: {
      reducer: (state, action: PayloadAction<{ eventType: string[]; eventCategory: string[] }>) => {
        state.filters.auctions = action.payload;
      },
      prepare: (eventType?: string[], eventCategory?: string[]) => ({
        payload: { eventType: eventType || [], eventCategory: eventCategory || [] },
      }),
    },
    /**
     * Set item ids of current filter result so that items from the same sale event
     * can be shown without needin g to remove from the state
     */
    setAvailableAuctionItems: {
      reducer: (
        state,
        action: PayloadAction<{ auctionId: number; page: number; items: IItem.Flat.ItemFlat[] }>,
      ) => {
        const { auctionId, page, items } = action.payload;
        const auction = state.filters.auction[auctionId];

        if (auction) {
          const itemIds = items.map((item) => item.id);
          auction.itemIds = page === 1 ? itemIds : [...(auction.itemIds || []), ...itemIds];
        }
      },
      prepare: (auctionId: number, page: number, items: IItem.Flat.ItemFlat[]) => ({
        payload: { auctionId, page, items },
      }),
    },
    setAvailableAuctionFilter: {
      reducer: (
        state,
        action: PayloadAction<{ auctionId: number; items: IItem.Flat.ItemFlat[] }>,
      ) => {
        const { auctionId, items } = action.payload;
        const year: string[] = [];
        const make: string[] = [];
        const model: string[] = [];
        const location: string[] = [];
        items.forEach((item) => {
          year.push(item.year);
          make.push(item.make);
          model.push(item.model);
          item.yardLoc && location.push(item.yardLoc);
        });
        state.filters.auction[auctionId] = {
          ...state.filters.auction[auctionId],
          available: {
            year: Array.from(new Set(year)).sort(),
            make: Array.from(new Set(make)).sort(),
            model: Array.from(new Set(model)).sort(),
            location: Array.from(new Set(location)).sort(),
          },
          selected: { year: [], make: [], model: [], location: [] },
        };
      },
      prepare: (auctionId: number, items: IItem.Flat.ItemFlat[]) => ({
        payload: { auctionId, items },
      }),
    },
    setSelectedAuctionFilter: {
      reducer: (state, action: PayloadAction<ItemsRequestBody>) => {
        const { saleEventId, year, make, model, location } = action.payload;
        if (saleEventId) {
          state.filters.auction[saleEventId] = {
            ...state.filters.auction[saleEventId],
            selected: {
              year: year || [],
              make: make || [],
              model: model || [],
              location: location || [],
            },
          };
        }
      },
      prepare: (payload: ItemsRequestBody) => ({ payload }),
    },

    setAvailableSearchFilter: {
      reducer: (state, action: PayloadAction<{ items: SearchItemResponse[] }>) => {
        const { items } = action.payload;
        const year: string[] = [];
        const make: string[] = [];
        const model: string[] = [];
        const condition: string[] = [];
        const category: string[] = [];
        items.forEach((item) => {
          year.push(item.year.raw!);
          make.push(item.make.raw!);
          model.push(item.model.raw!);
          condition.push(item.conditionType.raw);
          category.push(item.eventCategory.raw);
        });
        state.filters.search = {
          ...state.filters.search,
          available: {
            year: Array.from(new Set(year)).sort(),
            make: Array.from(new Set(make)).sort(),
            model: Array.from(new Set(model)).sort(),
            condition: Array.from(new Set(condition)).sort(),
            category: Array.from(new Set(category)).sort(),
          },
        };
      },
      prepare: (items: SearchItemResponse[]) => ({
        payload: { items },
      }),
    },
    setSelectedSearchFilter: {
      reducer: (state, action: PayloadAction<ItemsRequestBody>) => {
        const { year, make, model, condition, category } = action.payload;
        state.filters.search = {
          ...state.filters.search,
          selected: {
            year: year || [],
            make: make || [],
            model: model || [],
            condition: condition || [],
            category: category || [],
          },
        };
      },
      prepare: (payload: ItemsRequestBody) => ({ payload }),
    },

    setAvailablePurchaseV2Filter: {
      reducer: (
        state,
        action: PayloadAction<{
          items: PurchasesV2ResponseData | OffersResponseData;
        }>,
      ) => {
        const { items } = action.payload;

        const { type, make, model, location } = state.filters.purchaseV2.available;

        items.forEach((item) => {
          type.push(item.type);
          make.push(item.make);
          model.push(item.model);
          item.yardLoc && location.push(item.yardLoc);
        });

        state.filters.purchaseV2.available = {
          type: Array.from(new Set(type)).sort(),
          make: Array.from(new Set(make)).sort(),
          model: Array.from(new Set(model)).sort(),
          location: Array.from(new Set(location)).sort(),
        };
      },
      prepare: (items: PurchasesV2ResponseData | OffersResponseData) => ({
        payload: { items },
      }),
    },
    setSelectedPurchaseV2Filter: {
      reducer: (
        state,
        action: PayloadAction<{
          selectedFilters: string[];
          filterType: IGeneral.PurchaseV2FiltersType;
        }>,
      ) => {
        const { selectedFilters, filterType } = action.payload;
        state.filters.purchaseV2.selected = {
          ...state.filters.purchaseV2.selected,
          [filterType]: selectedFilters,
        };
      },
      prepare: (selectedFilters: string[], filterType: IGeneral.PurchaseV2FiltersType) => ({
        payload: { selectedFilters, filterType },
      }),
    },
    clearSelectedPurchaseV2Filter: {
      reducer: (state) => {
        state.filters.purchaseV2 = {
          ...state.filters.purchaseV2,
          selected: initialPurchaseV2FilterState,
        };
      },
      prepare: () => ({ payload: {} }),
    },
    clearPurchaseV2Filter: {
      reducer: (state) => {
        state.filters.purchaseV2 = {
          available: initialPurchaseV2FilterState,
          selected: initialPurchaseV2FilterState,
        };
      },
      prepare: () => ({ payload: {} }),
    },

    getDashboard: () => {},
    getDashboardFailure: (_state, _action: PayloadAction<string>) => {},

    getAuctions: (_state, _action: PayloadAction<AuctionFilterBody>) => {},
    getAuctionsFailure: (_state, _action: PayloadAction<string>) => {},

    getAuction: (_state, _action: PayloadAction<number>) => {},
    getAuctionFailure: (_state, _action: PayloadAction<string>) => {},

    getSearchSuggestions: (_state, _action: PayloadAction<string>) => {},
    getSearchSuggestionsFailure: (_state, _action: PayloadAction<string>) => {},

    // TODO:: move to item
    goToItemFromLink: {
      reducer: (
        _state,
        _action: PayloadAction<{ auctionId: number | null; itemId?: number | null }>,
      ) => {},
      prepare: (auctionId: number | null, itemId?: number | null) => ({
        payload: { auctionId, itemId },
      }),
    },
  },
  extraReducers: {
    [userActions.logout.type]: () => INITIAL_STATE,
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const dashboardActions = dashboardSlice.actions;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IEbid } from '../../models';
import { userActions } from '../user/slice';

const INITIAL_STATE: IEbid.EbidAuctionState = {
  increments: [],
  status: undefined,
  bidsHistory: {},
  totalBids: {},
  counts: {
    winning: 0,
    outbid: 0,
  },
  socketId: 'none',
  socketPingStart: 0,
  socketLatency: 0,
  socketPingId: 0,
};

const ebidAuctionSlice = createSlice({
  name: 'ebidAuction',
  initialState: INITIAL_STATE,
  reducers: {
    onConnectionStatus: {
      reducer: (state, action: PayloadAction<IEbid.SocketStatus>) => {
        state.status = action.payload;
      },
      prepare: (payload: IEbid.SocketStatus) => ({
        payload,
      }),
    },

    getBidHistory: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (itemId: number) => ({ payload: itemId }),
    },
    getBidHistorySuccess: {
      reducer: (state, action: PayloadAction<IEbid.BidHistoryAction>) => {
        const bidsHistory = action.payload.data;
        const itemId = action.payload.itemId;

        const formatted = bidsHistory.map((bid) => {
          const { amtAccepted, timestamp, id, clientUserId } = bid;
          const formattedBidHistory = { amtAccepted, timestamp, id, clientUserId };
          return formattedBidHistory;
        });

        state.bidsHistory[itemId] = formatted;
        state.totalBids[itemId] = action.payload.totalResults || 0;
      },
      prepare: (payload: IEbid.BidHistoryAction) => ({ payload }),
    },
    getBidHistoryFailure: {
      reducer: () => {},
      prepare: (error: string) => ({ payload: error }),
    },

    setBidHistory: {
      reducer: (state, action: PayloadAction<IEbid.HistoryType>) => {
        const bidHistory = action.payload;
        // Require itemId just to map bidsHistory and totalBids state
        const itemId = action.payload.itemId;
        if (itemId) {
          delete bidHistory.itemId;
          if (state.bidsHistory[itemId]) {
            state.bidsHistory[itemId].unshift(bidHistory);
          }
          state.totalBids[itemId] += 1;
        }
      },
      prepare: (payload: IEbid.HistoryType) => ({ payload }),
    },

    getIncrementConfig: () => {},
    getIncrementConfigSuccess: {
      reducer: (state, action: PayloadAction<IEbid.IncrementRange[]>) => {
        state.increments = action.payload;
      },
      prepare: (payload: IEbid.IncrementRange[]) => ({ payload }),
    },
    getIncrementConfigFailure: {
      reducer: () => {},
      prepare: (error: string) => ({ payload: error }),
    },

    setAutobid: {
      reducer: (_state, _action: PayloadAction<IEbid.SetAutobidAction>) => {},
      prepare: (payload: IEbid.SetAutobidAction) => ({ payload }),
    },

    watchEbidAuctionSocket: () => {},
    watchEbidAuctionSocketSuccess: () => {},
    watchEbidAuctionSocketFailure: {
      reducer: () => {},
      prepare: (error: string) => ({ payload: error }),
    },

    socketConnected: () => {},
    ebidAuctionSocketConnected: () => {},

    onSocketConnected: () => {},

    watchSocketCancel: () => {},

    onUserBalance: {
      reducer: (_state, _action: PayloadAction<IEbid.UserBalance>) => {},
      prepare: (payload: IEbid.UserBalance) => ({ payload }),
    },

    onUserFavorite: {
      reducer: (_state, _action: PayloadAction<IEbid.UserFavorite>) => {},
      prepare: (payload: IEbid.UserFavorite) => ({ payload }),
    },

    onUserPrebid: {
      reducer: (_state, _action: PayloadAction<IEbid.UserPrebid>) => {},
      prepare: (payload: IEbid.UserPrebid) => ({ payload }),
    },

    onSaleStatus: {
      reducer: (_state, _action: PayloadAction<IEbid.SaleStatus>) => {},
      prepare: (payload: IEbid.SaleStatus) => ({ payload }),
    },

    setCounts: {
      reducer: (state, action: PayloadAction<IEbid.CountsMap>) => {
        state.counts = action.payload;
      },
      prepare: (payload: IEbid.CountsMap) => ({ payload }),
    },

    subscribeEventBid: (_state, _action: PayloadAction<IEbid.SubscribeEventBidAction>) => {},
    subscribeItemBid: (_state, _action: PayloadAction<IEbid.SubscribeItemBidAction>) => {},

    updateCounts: () => {},

    setSocketId: {
      reducer: (state, action: PayloadAction<IEbid.SocketId>) => {
        state.socketId = action.payload;
      },
      prepare: (payload: IEbid.SocketId) => ({ payload }),
    },

    setSocketPingStart: {
      reducer: (state, action: PayloadAction<IEbid.SocketPingStart>) => {
        state.socketPingStart = action.payload;
      },
      prepare: (payload: IEbid.SocketPingStart) => ({ payload }),
    },

    // onSocketPong: () => {},
    pingSocket: () => {},

    setSocketPingId: {
      reducer: (state, action: PayloadAction<IEbid.SocketPingId>) => {
        state.socketPingId = action.payload;
      },
      prepare: (payload: IEbid.SocketPingId) => ({ payload }),
    },

    setSocketLatency: {
      reducer: (state, action: PayloadAction<IEbid.SocketLatency>) => {
        state.socketLatency = action.payload;
      },
      prepare: (payload: IEbid.SocketLatency) => ({ payload }),
    },

    relayLiveEventStats: (_state, _action: PayloadAction<IEbid.RelayStats>) => {},

    resetSendingState: () => {},

    updateSendingState: () => {},
  },
  extraReducers: {
    [userActions.logout.type]: () => INITIAL_STATE,
  },
});

export const ebidAuctionReducer = ebidAuctionSlice.reducer;

export const ebidActions = ebidAuctionSlice.actions;

import { Task } from '@redux-saga/types';
import i18n from 'i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { eventChannel } from 'redux-saga';
import { call, cancel, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import io, { Socket } from 'socket.io-client';
import { IBidding, IItem, ILive, IUser } from '../models';
import { appActions, itemActions, liveAuctionActions } from '../redux/actions';
import { composeItemFromLiveSocketResponse } from '../redux/item/utils';
import { ItemSelectors, LiveAuctionSelectors, UserSelectors } from '../redux/selectors';
import { api } from '../services/api';
import { LiveLotPreBidRequestBody } from '../services/api/request.types';
import {
  AddPrebidResponse,
  AttendeeLinkResponse,
  AuctionSocketTokenResponse,
  RemovePrebidResponse,
} from '../services/api/response.types';
import { calculateLiveNextBidAmount, parseCreditFormula } from '../utils/helpers';

function createLiveAuctionSocketClient(url: string, token: string) {
  return io.connect(url, {
    query: { jwt: token },
    transports: ['websocket'],
  });
}

function createLiveAuctionSocketEventChannel(socket: typeof Socket) {
  return eventChannel((emit) => {
    socket.on('connect', () => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: true,
          client: 'connect',
          error: undefined,
          retries: 0,
          attempt: 0,
        }),
      );
      socket.emit('send', { role: 'A' });
    });

    socket.on('error', (error: any) => {
      emit(liveAuctionActions.setConnectionStatus({ connected: false, client: 'error', error }));
    });

    socket.on('reconnecting', (retries: number) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'reconnecting',
          retries,
        }),
      );
    });

    socket.on('reconnect_error', (error: any) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'reconnect_error',
          error: error?.description?.message,
        }),
      );
    });

    socket.on('reconnect_failed', (error: any) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'reconnect_failed',
          error,
        }),
      );
    });

    socket.on('reconnect_attempt', (attempt: number) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'reconnect_attempt',
          attempt,
        }),
      );
    });

    socket.on('connect_timeout', (error: any) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'connect_timeout',
          error,
        }),
      );
    });

    socket.on('connect_error', (error: any) => {
      emit(
        liveAuctionActions.setConnectionStatus({
          connected: false,
          client: 'connect_error',
          error,
        }),
      );
    });

    socket.on('message', (message: ILive.LiveAuctionVcastAction) => {
      _logger.info('saga/live.socket onMessage', message);
      emit(message);
    });

    socket.on('disconect', (error: any) => {
      emit(
        liveAuctionActions.setConnectionStatus({ connected: false, client: 'disconnect', error }),
      );
      // emit(END); // Note that we should not END here so that client will retry connect after disconnect
    });

    return () => {
      socket.close();
      socket.removeAllListeners();
    };
  });
}

function* watchLiveAuctionSocket(
  action: ReturnType<typeof liveAuctionActions.attendLiveAuctionSuccess>,
) {
  const { url, token } = action.payload;
  const socket = createLiveAuctionSocketClient(url, token);
  const channel = createLiveAuctionSocketEventChannel(socket);
  const saga: Task = yield fork(liveAuctionSocketSaga, socket);

  try {
    while (true) {
      const _action: ILive.LiveAuctionVcastAction = yield take(channel);
      yield put(_action);
    }
  } finally {
    yield cancel(saga);
    channel.close();
  }
}

function* watchLiveAuction(action: ReturnType<typeof liveAuctionActions.attendLiveAuctionSuccess>) {
  yield race({
    socket: call(watchLiveAuctionSocket, action),
    cancel: take(liveAuctionActions.exitLiveAuction.type),
  });
}

function* socketConnected(socket: typeof Socket, action: ILive.VcastConnectedAction) {
  const eventId: ReturnType<typeof LiveAuctionSelectors.vcastEventId> = yield select(
    LiveAuctionSelectors.vcastEventId,
  );
  if (eventId && action) {
    const customer = action.doc?.Customers?.find((i) => i.Events.find((e) => e.ID === eventId));
    const bidder = customer?.User.Bidders[0];
    if (bidder) {
      yield put(liveAuctionActions.bidderUpdated(bidder));
      const doc = {
        EventID: eventId,
        Status: 'MOBILE',
        BidderBadge: bidder.BidderBadge,
        Action: 'ADD',
      };

      socket.emit('send', { type: 'AttendeeStatus', doc });
    }
  }
}

function* socketAuctionStatus(_: typeof Socket, action: ILive.VcastEventStatsAction) {
  const eventStats = action.doc.Stats;

  if (!eventStats) {
    return;
  }

  const currentItem = eventStats.CurrentItem;

  if (currentItem) {
    yield put(liveAuctionActions.setCurrentItem(currentItem));
    const item = composeItemFromLiveSocketResponse(currentItem);
    yield put(itemActions.addItem(item));
  }

  const Running = eventStats.Running === 1;
  const isCurrentBidTypeASK = eventStats.CurrentBidType === 'ASK';

  let liveBidder: ReturnType<typeof LiveAuctionSelectors.bidder> = yield select(
    LiveAuctionSelectors.bidder,
  );

  if (liveBidder && Running) {
    const bidder = cloneDeep(liveBidder); // create new object to add or remove keys
    const BidderBadge = bidder.BidderBadge;
    const CurrentBidID = (bidder.CurrentBidID = eventStats?.CurrentBidID);
    bidder.PreviousBids = bidder.PreviousBids || {};

    // reset PreviousBids if clerk undo to ASK
    if (isCurrentBidTypeASK) {
      bidder.PreviousBids = {};
    }

    // When the clerk undo, remove previous bids that `id`s are bigger than current bid id
    Object.keys(bidder.PreviousBids)
      .sort()
      .forEach((id) => {
        if (+id > CurrentBidID) {
          // we must invalidate from PreviousBids when clerk peform Undo
          delete bidder?.PreviousBids[id];
        }
      });

    const bids = eventStats.Bids; // Included only in first socket message of item
    if (bids) {
      // bidder have bids for current item
      const hasBids = bids.some((bid) => bid.Active && bid.Badge === BidderBadge);

      let lastBidAmount = 0;

      bids.forEach((bid) => {
        const bidAmount = bid.Active && bid.Badge === BidderBadge && bid.Amount;
        if (bidAmount && bidder && bidder.PreviousBids) {
          // always store bid history of bidder for the item
          bidder.PreviousBids[bid.ID] = bidAmount;
        }

        if (bidAmount > lastBidAmount) {
          lastBidAmount = bidAmount as number;
        }
      });

      bidder.HasBids = !!hasBids; // bidder has active Bids for the current item
      bidder.LastBidAmount = lastBidAmount;
    }

    const HighestBidder = eventStats.CurrentBidBadge === BidderBadge;
    let winningItemDeposit = 0;
    if (HighestBidder) {
      // bidder has bids
      bidder.HasBids = true;
      bidder.PreviousBids[CurrentBidID] = eventStats?.CurrentBidAmount;
      bidder.HighestBidder = true;
      winningItemDeposit = parseCreditFormula(eventStats.ItemSettings.CreditFormula);
    } else {
      bidder.HighestBidder = false;
    }

    yield put(liveAuctionActions.setLiveDeposit(winningItemDeposit));

    bidder.HasOutbid =
      (eventStats.CurrentBidAmount && !isCurrentBidTypeASK && !HighestBidder && bidder.HasBids) ||
      false;
    bidder.CurrentBidAmount = !isCurrentBidTypeASK && eventStats?.CurrentBidAmount;

    /**
     * Bidder’s `lastBidAmount` should reflect the last amount the bidder submitted.
     * But when the clerk click on undo, the bidder’s last bid amount is cancelled
     * and the previous bid the bidder submitted become the bidder’s last bid amount.
     * Bidder's previous bids are saved in `bidder.PreviousBids`.
     */
    if (bidder.PreviousBids && !isNaN(CurrentBidID)) {
      const bidIds = Object.keys(bidder.PreviousBids);
      const lastBidId = bidIds
        .sort()
        .reverse()
        .find((id) => +id <= CurrentBidID);

      if (lastBidId) {
        bidder.LastBidAmount = bidder.PreviousBids[lastBidId];
      } else {
        // there is no bid related to user anymore (undo)
        bidder.LastBidAmount = undefined;
        bidder.HasBids = false;
        bidder.HasOutbid = false;
      }
    }

    const hasReset = eventStats.CurrentBidAmount === null;
    if (hasReset) {
      // clerk reset
      bidder.HasBids = false;
      bidder.PreviousBids = {};
    }

    yield put(liveAuctionActions.bidderUpdated(bidder));

    const saleEventId: IItem.SaleEvent['id'] = yield select(LiveAuctionSelectors.saleEventId);
    const biddingStatus = getBiddingStatus(eventStats.CurrentBidType, bidder);
    const isHighestBidder = bidder.HighestBidder;
    const isWon = biddingStatus === IBidding.STATUS.WON;
    const isOffer = biddingStatus === IBidding.STATUS.OFFER;
    const isLost = biddingStatus === IBidding.STATUS.LOST;
    const isSold = ['PAUSE-SOLD', 'SOLD'].includes(eventStats.CurrentBidType);
    const isNoSale = ['NOSALE'].includes(eventStats.CurrentBidType);
    const isAsking = ['ASK'].includes(eventStats.CurrentBidType);
    const isWinning = isHighestBidder && !isOffer && !isNoSale;
    const isOfferWinner = isOffer && eventStats?.CurrentBidBadge === bidder?.BidderBadge;

    let bidding: IBidding.Bidding = {
      status: biddingStatus,
      auctionStatus: eventStats.Status,
      type: 'LIVE',
      bidsCount: eventStats.Bids?.length,
      currentBidAmount: eventStats.CurrentBidAmount!,
      nextBidAmount: calculateLiveNextBidAmount(eventStats.CurrentBidAmount!, eventStats.Increment),
      lastBidAmount: bidder.LastBidAmount || 0,
      maxBidAmount: bidder.CreditLimit,
      isMaxBidActive: false,
      startTime: moment(eventStats.EventStart).unix(),
      endTime: 0, // not valid for live auction
      isRunning: eventStats.Running === 1,
      sendingState: {
        isSending: bidder.SendingBid,
      },
      hasBids: bidder.HasBids, // has the current user bids for the item
      isWinning,
      isOutbid: bidder.HasOutbid && biddingStatus === IBidding.STATUS.OUTBID,
      isAsking,
      userBadge: bidder.BidderBadge,
      winnerBadge: eventStats.CurrentBidBadge,
      isSold,
      isWon,
      isLost,
      isStaged: false,
      isEnded: false,
      isOffer,
      isOfferWinner,
      isNoSale,
      saleEventId,
    };

    let offer: IItem.Offer | undefined;
    if (isOffer && eventStats.CurrentBidAmount && eventStats.CurrentBidBadge) {
      offer = {
        badgeNo: +eventStats.CurrentBidBadge,
        offerAmount: eventStats.CurrentBidAmount,
        saleEventId,
        saleStatus: biddingStatus,
        offerAt: eventStats.ServerTime,
      };
    }

    const _currentItem: ReturnType<typeof LiveAuctionSelectors.currentItem> = yield select(
      LiveAuctionSelectors.currentItem,
    );

    if (_currentItem?.ID === eventStats.CurrentItemID) {
      const itemId = Number(_currentItem?.ExternalID);
      yield put(itemActions.setOffers([{ itemId, offer }]));
      yield put(itemActions.setBidding(itemId, bidding));
    }

    // Blocking/Unblock Deposit Logic - start
    // const shouldUnblock =
    //   (bidder.HasBids &&
    //     (bidder.HasOutbid || (isSold && !isWon) || isLost || isOffer || isNoSale || isAsking)) ||
    //   (isWinning && isNoSale) ||
    //   (isWinning && isOffer);

    // const account: IUser.Account = yield select(UserSelectors.account);
    // if (account && _currentItem?.ExternalID) {
    //   const accountState = { ...account };
    //   const itemId = Number(_currentItem.ExternalID);

    //   // relay live auction event stats
    //   const relayStats = {
    //     _userId: account.id,
    //     _saleEventId: saleEventId,
    //     _itemId: itemId,
    //     CurrentItem: eventStats.CurrentItem,
    //     CurrentBidAmount: eventStats.CurrentBidAmount,
    //     CurrentBidBadge: eventStats.CurrentBidBadge,
    //     CurrentBidType: eventStats.CurrentBidType,
    //     CurrentBidID: eventStats.CurrentBidID,
    //   };
    //   yield put(ebidActions.relayLiveEventStats(relayStats));

    //   let blockedItems: { liveItems: []; ebidItems: [] } = yield select(UserSelectors.blockedItems);
    //   let unblockedItems: { liveItems: []; ebidItems: [] } = yield select(
    //     UserSelectors.unblockedItems,
    //   );

    //   const blocked = blockedItems?.liveItems.includes(itemId);
    //   const unblocked = unblockedItems?.liveItems.includes(itemId);

    //   if (isWinning && !blocked) {
    //     if (accountState.availableBalance >= _currentItem?.Deposit) {
    //       // proactively deduct from state before receive update from user_balance
    //       accountState.availableBalance = accountState.availableBalance - _currentItem?.Deposit;
    //       accountState.blockedAmount = accountState.blockedAmount + _currentItem?.Deposit;
    //     }
    //     yield put(
    //       userActions.updateBlockItem({
    //         type: 'blocked',
    //         biddingType: IBidding.TYPES.LIVE,
    //         itemId,
    //       }),
    //     );
    //   } else if (shouldUnblock && !unblocked) {
    //     accountState.availableBalance = accountState.availableBalance + _currentItem?.Deposit;
    //     accountState.blockedAmount = accountState.blockedAmount - _currentItem?.Deposit;
    //     yield put(
    //       userActions.updateBlockItem({
    //         type: 'unblocked',
    //         biddingType: IBidding.TYPES.LIVE,
    //         itemId,
    //       }),
    //     );
    //   }
    //   yield put(userActions.getUserAccountSuccess(accountState));
    // }
    // Blocking/Unblock Deposit Logic - end
  }

  yield put(liveAuctionActions.statusUpdated(action.doc.Stats));
}

const getBiddingStatus = (bidType: ILive.BidType, bidder?: ILive.VcastBidder): IBidding.Status => {
  if (['PAUSE-IF', 'IF'].includes(bidType)) {
    return IBidding.STATUS.OFFER;
  } else if (bidder && bidder?.HasOutbid && !['PAUSE-SOLD', 'SOLD'].includes(bidType)) {
    return IBidding.STATUS.OUTBID;
  } else if (
    ['PAUSE-SOLD', 'SOLD'].includes(bidType) &&
    bidder &&
    bidder.HasBids &&
    bidder.HighestBidder
  ) {
    return IBidding.STATUS.WON;
  } else if (
    ['PAUSE-SOLD', 'SOLD'].includes(bidType) &&
    bidder &&
    bidder.HasBids &&
    !bidder.HighestBidder
  ) {
    return IBidding.STATUS.LOST;
  } else if (['ONLINE'].includes(bidType)) {
    return IBidding.STATUS.LOTTED;
  } else {
    return IBidding.STATUS.LIVE;
  }
};

function* socketFieldUpdate(_: typeof Socket, action: ILive.VcastSetFieldAction) {
  const auctionStatus: ReturnType<typeof LiveAuctionSelectors.auctionStatus> = yield select(
    LiveAuctionSelectors.auctionStatus,
  );
  if (auctionStatus) {
    switch (action.doc.Field) {
      case 'INCREMENT': {
        // * update item's next bidding amount
        const currentItem: ReturnType<typeof LiveAuctionSelectors.currentItem> = yield select(
          LiveAuctionSelectors.currentItem,
        );
        if (currentItem && currentItem.ExternalID) {
          const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
          const item = items[+currentItem.ExternalID];
          if (item && item.bidding) {
            yield put(
              itemActions.setBidding(item.id, {
                nextBidAmount: calculateLiveNextBidAmount(
                  item.bidding.currentBidAmount,
                  action.doc.Value,
                ),
              }),
            );
          }
        }

        yield put(
          liveAuctionActions.statusUpdated({
            ...auctionStatus,
            Increment: +action.doc.Value,
          }),
        );
        break;
      }
      case 'MESSAGE': {
        yield put(
          liveAuctionActions.statusUpdated({
            ...auctionStatus,
            StatusMessage: action.doc.Value as ILive.SocketBidStatus,
          }),
        );
        break;
      }
    }
  }
}

function* socketProposeBidStatus(
  _: SocketIOClient.Socket,
  action: ILive.VcastProposeBidStatusAction,
) {
  const auction: ReturnType<typeof LiveAuctionSelectors.auctionStatus> = yield select(
    LiveAuctionSelectors.auctionStatus,
  );
  const bidder: ReturnType<typeof LiveAuctionSelectors.bidder> = yield select(
    LiveAuctionSelectors.bidder,
  );
  const _bidder = cloneDeep(bidder);

  if (_bidder && auction) {
    const message = action.doc.Status;

    switch (message) {
      case 'Accepted': {
        _bidder.LastBidAccepted = true;
        break;
      }
    }

    _bidder.SendingBid = false;

    const currentItem: ReturnType<typeof LiveAuctionSelectors.currentItem> = yield select(
      LiveAuctionSelectors.currentItem,
    );

    if (currentItem && currentItem.ID === action.doc.ItemID) {
      const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
      const item = cloneDeep(items[Number(currentItem.ExternalID)]);
      if (item && item.bidding && item.bidding.sendingState) {
        item.bidding.sendingState.isSending = false;
        yield put(itemActions.setBidding(item.id, item.bidding));
      }
    }

    yield put(liveAuctionActions.bidderUpdated(_bidder));
    yield put(liveAuctionActions.statusUpdated(auction));
  }
}

function* socketUserUpdate(_: typeof Socket, action: ILive.VcastUserAction) {
  if (action) {
    let bidders;
    const v2Bidder = action?.doc?.Customer?.User;
    if (v2Bidder) {
      bidders = action?.doc?.Customer?.User?.Bidders;
    } else {
      bidders = action?.doc?.Bidders;
    }
    const bidder = bidders && bidders[0];
    if (bidder) {
      yield put(liveAuctionActions.bidderUpdated(bidder));
    }
  }
}

function* sendSocketProposeBid(
  socket: SocketIOClient.Socket,
  action: ReturnType<typeof liveAuctionActions.sendBid>,
) {
  const { itemId, amount } = action.payload;
  const eventId: ReturnType<typeof LiveAuctionSelectors.vcastEventId> = yield select(
    LiveAuctionSelectors.vcastEventId,
  );
  const bidder: ReturnType<typeof LiveAuctionSelectors.bidder> = yield select(
    LiveAuctionSelectors.bidder,
  );
  if (bidder) {
    const _bidder = cloneDeep(bidder);
    const proposeBid = {
      type: 'ProposeBid',
      doc: {
        EventID: eventId,
        ItemID: itemId,
        Badge: _bidder.BidderBadge,
        PayType: '',
        Amount: amount,
        Status: 'PROPOSE',
      },
    };
    _bidder.SendingBid = true;
    yield put(liveAuctionActions.bidderUpdated(_bidder));
    socket.emit('send', proposeBid);
  }
}

export function* liveAuctionSocketSaga(socket: SocketIOClient.Socket) {
  yield takeLatest(ILive.ACTION_TYPES.CONNECTED, socketConnected, socket);
  yield takeLatest(ILive.ACTION_TYPES.EVENT_STATS, socketAuctionStatus, socket);
  yield takeLatest(ILive.ACTION_TYPES.SET_FIELD, socketFieldUpdate, socket);
  yield takeLatest(ILive.ACTION_TYPES.PROPOSE_BID_STATUS, socketProposeBidStatus, socket);
  yield takeLatest(ILive.ACTION_TYPES.USER, socketUserUpdate, socket);
  yield takeLatest(liveAuctionActions.sendBid.type, sendSocketProposeBid, socket);
}

function* attendLiveAuction(action: ReturnType<typeof liveAuctionActions.attendLiveAuction>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const id = action.payload;
  yield put(liveAuctionActions.getAttendeeLink(id));

  const attendAuctionRace: {
    success?: ReturnType<typeof liveAuctionActions.getAttendeeLinkSuccess>;
    error?: ReturnType<typeof liveAuctionActions.getAttendeeLinkFailure>;
  } = yield race({
    success: take(liveAuctionActions.getAttendeeLinkSuccess.type),
    error: take(liveAuctionActions.getAttendeeLinkFailure.type),
  });

  if (attendAuctionRace.error) {
    yield put(liveAuctionActions.attendLiveAuctionFailure('attendLiveAuction error'));
  } else if (attendAuctionRace.success) {
    const { url, host, socketToken } = attendAuctionRace.success.payload;

    let token;

    if (!socketToken) {
      const getToken: AuctionSocketTokenResponse = yield call(api.getAuctionSocketToken, url);

      if (!getToken.ok || !getToken.data) {
        yield put(liveAuctionActions.attendLiveAuctionFailure('attendLiveAuction error'));
      } else {
        token = getToken.data;
      }
    } else {
      token = socketToken;
    }

    if (token) {
      yield put(liveAuctionActions.attendLiveAuctionSuccess(host, token));
    }
  }
}

function* getAuctionAttendeeLink(action: ReturnType<typeof liveAuctionActions.getAttendeeLink>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const id = action.payload;

  const response: AttendeeLinkResponse = yield call(api.getAuctionAttendeeLink, id, account);

  if (response.ok && response.data) {
    yield put(liveAuctionActions.getAttendeeLinkSuccess(response.data));
  } else {
    yield put(liveAuctionActions.getAttendeeLinkFailure('getLiveAuctionSocket error'));
  }
}

function* addItemPrebid(action: ReturnType<typeof liveAuctionActions.addItemPrebid>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const status: IUser.AccountStatus = yield select(UserSelectors.accountStatus);

  if (status === 'PENDING') {
    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: 'Sorry!',
        message: i18n.t('infos:your_account_has_not'),
      }),
    );
    return;
  }
  const { itemId, __saleEventId, amount } = action.payload;

  const params: LiveLotPreBidRequestBody = {
    itemId: itemId.toString(),
    __saleEventId: __saleEventId.toString(),
    amount,
  };

  const response: AddPrebidResponse = yield call(api.addLiveLotPreBid, params);

  if (response.ok && response.data) {
    const { item, event } = response.data;

    yield put(liveAuctionActions.addItemPrebidSuccess(event, item));
    yield put(
      appActions.showAlert({
        alertType: 'dropdown',
        title: 'Pickles',
        message: 'Your Max AutoBid is accepted',
        messageType: 'info',
      }),
    );
    yield put(itemActions.getPrebids());
  } else {
    yield put(liveAuctionActions.addItemPrebidFailure('addLiveLotPreBid error'));
  }
}

function* removeItemPrebid(action: ReturnType<typeof liveAuctionActions.removeItemPrebid>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const { prebidId, itemId } = action.payload;
  const response: RemovePrebidResponse = yield call(api.deleteLiveLotPreBid, prebidId);

  if (response.ok) {
    yield put(liveAuctionActions.removeItemPrebidSuccess(itemId, prebidId));
    yield put(itemActions.removePrebidSuccess(itemId));
  } else {
    yield put(liveAuctionActions.removeItemPrebidFailure('deleteLiveLotPreBid error'));
  }
}

export default function* liveAuctionSaga() {
  yield takeLatest(liveAuctionActions.addItemPrebid.type, addItemPrebid);
  yield takeLatest(liveAuctionActions.removeItemPrebid.type, removeItemPrebid);
  yield takeLatest(liveAuctionActions.attendLiveAuction.type, attendLiveAuction);
  yield takeLatest(liveAuctionActions.attendLiveAuctionSuccess.type, watchLiveAuction);
  yield takeLatest(liveAuctionActions.getAttendeeLink.type, getAuctionAttendeeLink);
}

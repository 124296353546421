export interface Vehicle {
  make: string;
  model: string;
  series?: string | null;
  year: string;
  engine?: string;
  color?: string;
  // crBurnt: number;
  crGrade?: string;
  crIncidentType?: IncidentType;
  driveTrain?: unknown;
  engineRef: string;
  fuelType?: unknown;
  intColor?: unknown;
  interior?: unknown;
  keys?: number | null;
  odometer?: number;
  odoUnits: OdoUnit;
  regExp?: string;
  regNo: string;
  tranType?: string;
  trim?: string;
  vin: string;
  mileage?: number;
}

export enum ODO_UNIT {
  KM = 'KM',
  MILES = 'MILES',
}
export type OdoUnit = keyof typeof ODO_UNIT;
export const ItemOdoUnitsMap = {
  [ODO_UNIT.KM]: 'Kilometers',
  [ODO_UNIT.MILES]: 'Miles',
};

export enum INCIDENT_TYPE {
  FIRE = 'FIRE',
  HAIL = 'HAIL',
  IMPACT = 'IMPACT',
  OTHER = 'OTHER',
  ROLLOVER = 'ROLLOVER',
  VANDALISM = 'VANDALISM',
  WATER = 'WATER',
}
export type IncidentType = keyof typeof INCIDENT_TYPE;

import { RootState } from '..';

export const ApplicationSelectors = {
  language: (state: RootState) => state.application.language,

  timer: (state: RootState) => state.application.datetime,

  mobileAppConfig: (state: RootState) => state.application.config,

  environment: (state: RootState) => state.application.environment,

  platform: (state: RootState) => state.application.platform,
};

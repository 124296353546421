import { IAuction, IEbid, IGeneral, IItem } from '..';
import { OdoUnit } from './item.vehicle.types';

export enum RESPONSE_TYPE {
  'ITEM' = 'ITEM',
  'OFFER' = 'OFFER',
  'FAVORITE' = 'FAVORITE',
  'PURCHASE' = 'PURCHASE',
  'PURCHASEV2' = 'PURCHASEV2',
  'PAYMENT_DETAILS' = 'PAYMENT_DETAILS',
  'PREBID' = 'PREBID',
  'RECOMMENDATION' = 'RECOMMENDATION',
  'EBID' = 'EBID',
  'BID_ACTIVITY' = 'BID_ACTIVITY',
}

export type ResponseType = keyof typeof RESPONSE_TYPE;

interface ItemBaseFlat {
  responseType: ResponseType;
  city: string;
  conditionType: IItem.ConditionType;
  depositAmt: number;
  id: number;
  image: string | null;
  lotNum: string | null;
  make: string;
  model: string;
  name: string;
  odometer: number | null;
  odoUnits: IItem.Vehicle.OdoUnit;
  regNo: string;
  saleEventId: number | null;
  saleEventName: string | null;
  saleEventType: IAuction.AuctionTypes;
  saleStatus: IItem.SaleStatus | null;
  sourceType: string;
  status: IItem.Status;
  stockNum: string;
  type: IItem.Type;
  yardName: string;
  year: string;
}

export interface ItemFlat extends ItemBaseFlat {
  responseType: 'ITEM';
  active: number;
  auctionDate: string;
  auctionPrice: string;
  auctionRemarks: string | null;
  auctionStatus?: IEbid.AuctionStatus;
  burnt: number;
  chassisNumber: string | null;
  cityCode: string;
  color: string;
  consignor: string;
  consignorName: string;
  consignType: IItem.ConsignType;
  country: string | null;
  cr: string;
  crBurnt: number;
  crIncidentType: string | null;
  currentBid?: number | null;
  description: string | null;
  engineNumber: string | null;
  engineRef: string | null;
  eventName: string | null;
  eventType: IAuction.AuctionTypes | null;
  exteriorColor: string | null;
  favedBy: number[] | null;
  geo: { lat: number; lon: number };
  images: string[];
  incidentType: IItem.Vehicle.IncidentType | null;
  inspectionRemarks: string | null;
  intColor: string | null;
  itemType: IItem.Type;
  itemUpdated: string;
  keys: number | null;
  mileage: number | null;
  minStartingBidAmt: number | null;
  notes: string | null;
  reservePrice: number | null;
  saleEventEndAt: string | null;
  saleEventStartAt: string | null;
  saleItemCreated: string | null;
  saleItemUpdated: string | null;
  series: string | null;
  trim: string | null;
  variant: string | null; // same as trim in the elastic search indexing
  vin: string | null;
  yardLoc: string | null;
  yearSearch?: string;
  viewsCount?: number;
}

export interface OfferFlat
  extends Omit<
    ItemBaseFlat,
    | 'depositAmt'
    | 'id'
    | 'saleEventName'
    | 'saleEventType'
    | 'saleEventType'
    | 'status'
    | 'yardName'
  > {
  responseType: 'OFFER';
  auctionName: string;
  badgeNo: number;
  cr: string | null;
  eventCategory: IGeneral.EventCategory;
  eventType: IAuction.AuctionTypes;
  itemId: number;
  moc: string | null;
  offerAmount: number;
  offerAt: string;
  saleStatus: IItem.SaleStatus;
  userId: number;
  yardLoc: string;
}

export interface FavoriteFlat extends Omit<ItemBaseFlat, 'name'> {
  responseType: 'FAVORITE';
  favorite: number;
  itemId: number;
  itemName: string;
  notes: string | null;
  userId: number;
  saleStartAt: string | null;
  saleEndAt: string | null;
}

export interface PurchaseFlat
  extends Omit<
    ItemBaseFlat,
    | 'depositAmt'
    | 'odometer'
    | 'odoUnits'
    | 'regNo'
    | 'saleEventId'
    | 'saleEventType'
    | 'saleStatus'
    | 'yardName'
  > {
  responseType: 'PURCHASE';
  cr: string | null;
  crGrade: string | null;
  dueAmt: number | null;
  eventCategory: IGeneral.EventCategory;
  eventType: IAuction.AuctionTypes | null;
  itemId: number;
  moc: string | null;
  payBy: string;
  showDueAmt: boolean;
  soldAmt: number;
  soldAt: string;
  towBy: string | null;
  needPayment: boolean;
}

export interface PurchaseV2Flat
  extends Omit<
    ItemBaseFlat,
    | 'city'
    | 'depositAmt'
    | 'lotNum'
    | 'odometer'
    | 'odoUnits'
    | 'saleEventId'
    | 'saleEventName'
    | 'saleEventType'
    | 'saleStatus'
    | 'sourceType'
    | 'yardName'
  > {
  responseType: RESPONSE_TYPE.PURCHASEV2;
  consignorName: string;
  consignorPaymentReconciliation: IGeneral.ConsignorPaymentReconciliationType;
  eventCategory: IGeneral.EventCategory;
  finalizedAt: string | null;
  invoices: IItem.ItemPurchaseInvoice[];
  isItemBalancePayableToConsignor: boolean;
  itemId: number;
  payBy: string;
  soldAmt: number;
  soldAt: string;
  yardLoc: string | null;
}

export interface PaymentDetailsFlat {
  responseType: RESPONSE_TYPE.PAYMENT_DETAILS;
  id: number;
  itemId: number;
  status: IItem.Status;
  finalizedAt: string | null;
  invoices: IItem.ItemPurchaseInvoice[];
  paymentTerms: IGeneral.PaymentTermsType;
  isItemBalancePayableToConsignor: boolean;
  payBy: string;
  soldAmt: number;
  soldAt: string;
  paymentHistory: IItem.ItemPaymentSubmission[];
}

export interface PaymentSubmissionFlat {
  buyerPaymentId: number;
  userId: number;
  paymentMethod: IGeneral.PaymentMethod;
  paymentReference: string;
  status: IItem.PaymentSubmissionStatusType;
  createAt: string;
  payments: IItem.PaymentItemRecord[];
}

export interface PrebidFlat extends ItemBaseFlat {
  responseType: 'PREBID';
  amount: number;
  userId: number;
  itemId: number;
  itemName: string;
  saleStartAt: string;
  saleEndAt: string;
}

export interface RecommendationFlat extends Omit<ItemBaseFlat, 'saleEventType' | 'yardName'> {
  responseType: 'RECOMMENDATION';
  consignType: IItem.ConsignType;
  minStartingBidAmt: number | null;
  description: string | null;
  reservePrice: number | null;
  auctionPrice: number | string;
  auctionStatus: string | null;
  currentBid: number | null;
  auctionDate: string;
  itemUpdated: string;
  saleItemCreated: string | null;
  saleItemUpdated: string | null;
  cityCode: string;
  makeModel: string;
  variant: string | null;
  trim: string | null;
  series: string | null;
  eventName: string;
  eventType: IAuction.AuctionTypes | null;
  cr: string;
  burnt: number;
  crBurnt: number;
  chassisNumber: string;
  vin: string;
  consignor: string;
  consignorName: string;
  engineNumber: string;
  engineRef: string;
  exteriorColor: string;
  color: string;
  incidentType: IItem.Vehicle.IncidentType;
  crIncidentType: IItem.Vehicle.IncidentType;
  auctionRemarks: string | null;
  inspectionRemarks: string | null;
  keys: number;
  mileage: number | null;
  intColor: string | null;
  yardLoc: string;
  images: string[];
}

export interface EbidItemFlat {
  responseType: 'EBID';
  id: number;
  auctionStatus: IEbid.AUCTION_STATUS;
  autobidActive: boolean;
  bidCount: number;
  city: string;
  clientItemId: string;
  conditionType: string;
  currentBidAmt: number;
  depositAmt: number;
  description: string;
  endAt: string;
  endTime: number;
  eventId: string;
  eventName: string;
  eventType: string;
  image: string;
  images: string[];
  lastBid: number;
  make: string;
  maxAutobid: number;
  minStartingBidAmt: number;
  model: string;
  nextBidAmt: number;
  nextBidInput: number;
  odometer: number;
  odoUnits: string;
  saleStatus: string | undefined;
  startAt: string;
  startTime: number;
  winnerId: string | null;
  year: string;
}

export interface BidActivityFlat {
  responseType: 'BID_ACTIVITY';
  clientItemId: string;
  name: string;
  lotNum: string;
  stockNum: string;
  bidCount: number;
  currentBidAmt: number;
  winnerId: string;
  eventId: string;
  eventName: string;
  description: string | null;
  imgUrl: string;
  startTime: number;
  endTime: number;
  minStartingBid: number;
  minBalanceReq: number;
  auctionStatus: IEbid.AUCTION_STATUS;
  saleStatus: string | null;
  saleMessage: string | null;
  startAt: string;
  endAt: string;
  lastBid: number;
  odometer: number;
  odoUnits: OdoUnit;
  conditionType: string;
  city: string;
  bids: {
    id: string;
    clientUserId: string;
    clientItemId: string;
    amtPlaced: number;
    amtAccepted: number;
    isAccepted: number;
    statusCode: number;
    statusMessage: string;
    bidType: string;
    receivedAt: {};
    itemName: string;
    eventName: string;
  }[];
}

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import * as IResponse from '../../services/api/response.types';
import { IAuction, ILive } from '../../models';
import { userActions } from '../user/slice';
import _ from 'lodash';
import { dashboardActions } from '../actions';
export interface LiveAuctionState {
  vcastEventId?: number;
  saleEventId?: number;
  auctionStatus?: Partial<ILive.VcastAuctionStats>;
  currentItem?: ILive.VcastAuctionItem;
  bidder?: Partial<ILive.VcastBidder>;
  connectionStatus?: ILive.SocketStatus;
  agora: {
    agoraToken?: string;
    agoraChannel?: string;
    agoraAppId?: string;
  };
  winningItemDeposit: number;
}

/**
 * State information are related to information coming from `Velocicast`
 */
const INITIAL_STATE: LiveAuctionState = {
  vcastEventId: undefined,
  saleEventId: undefined,
  auctionStatus: undefined,
  currentItem: undefined,
  bidder: undefined,
  connectionStatus: undefined,
  agora: {
    agoraToken: undefined,
    agoraChannel: undefined,
    agoraAppId: undefined,
  },
  winningItemDeposit: 0,
};

const liveAuctionSlice = createSlice({
  name: 'live-auction',
  initialState: INITIAL_STATE,
  reducers: {
    attendLiveAuction: {
      reducer: (state, action: PayloadAction<number>) => {
        state.saleEventId = action.payload;
      },
      prepare: (auctionId: number) => ({ payload: auctionId }),
    },
    attendLiveAuctionSuccess: {
      reducer: (_state, _action: PayloadAction<{ url: string; token: string }>) => {},
      prepare: (url: string, token: string) => ({ payload: { url, token } }),
    },
    attendLiveAuctionFailure: (_state, _action: PayloadAction<string>) => {},

    getAttendeeLink: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (id: number) => ({ payload: id }),
    },
    getAttendeeLinkSuccess: {
      reducer: (state, action: PayloadAction<IResponse.AttendeeLinkResponseData>) => {
        const { event, agoraChannel, agoraToken, agoraAppId } = action.payload;
        state.vcastEventId = +event;
        state.agora = { agoraChannel, agoraToken, agoraAppId };
      },
      prepare: (payload: IResponse.AttendeeLinkResponseData) => ({ payload }),
    },
    getAttendeeLinkFailure: (_state, _action: PayloadAction<string>) => {},

    statusUpdated: {
      reducer: (state, action: PayloadAction<Partial<ILive.VcastAuctionStats>>) => {
        state.auctionStatus = _.merge(state.auctionStatus || {}, action.payload);
      },
      prepare: (status: Partial<ILive.VcastAuctionStats>) => ({ payload: status }),
    },

    setCurrentItem: {
      reducer: (state, action: PayloadAction<ILive.VcastAuctionItem>) => {
        state.currentItem = { ...(state.currentItem || {}), ...action.payload };
      },
      prepare: (item: ILive.VcastAuctionItem) => ({ payload: item }),
    },

    userUpdated: {
      reducer: (_state, _action: PayloadAction<ILive.VcastUser>) => {},
      prepare: (user: ILive.VcastUser) => ({ payload: user }),
    },

    bidderUpdated: {
      reducer: (state, action: PayloadAction<Partial<ILive.VcastBidder>>) => {
        state.bidder = _.merge(state.bidder || {}, action.payload);
      },
      prepare: (bidder: Partial<ILive.VcastBidder>) => ({ payload: bidder }),
    },

    setConnectionStatus: {
      reducer: (state, action: PayloadAction<ILive.SocketStatus>) => {
        const status = action.payload;
        if (status.connected === false) {
          state.bidder = state.bidder && { ...state.bidder, SendingBid: false };
        }
        state.connectionStatus = { ...(state.connectionStatus || {}), ...status };
      },
      prepare: (connectionStatus: ILive.SocketStatus) => ({ payload: connectionStatus }),
    },

    sendBid: {
      reducer: (_state, _action: PayloadAction<{ itemId: number; amount: number }>) => {},
      prepare: (itemId: number, amount: number) => ({ payload: { itemId, amount } }),
    },

    addItemPrebid: {
      reducer: (
        _state,
        _action: PayloadAction<{ itemId: number; __saleEventId: number; amount: number }>,
      ) => {},
      prepare: (itemId: number, eventId: number, amount: number) => ({
        payload: { itemId, __saleEventId: eventId, amount },
      }),
    },
    addItemPrebidSuccess: {
      reducer: (
        _state,
        _action: PayloadAction<{ auction: IAuction.Auction; item: IResponse.ItemResponse }>,
      ) => {},
      prepare: (auction: IAuction.Auction, item: IResponse.ItemResponse) => ({
        payload: { auction, item },
      }),
    },
    addItemPrebidFailure: (_state, _action: PayloadAction<string>) => {},

    removeItemPrebid: {
      reducer: (_state, _action: PayloadAction<{ itemId: number; prebidId: number }>) => {},
      prepare: (itemId: number, prebidId: number) => ({ payload: { itemId, prebidId } }),
    },
    removeItemPrebidSuccess: {
      reducer: (_state, _action: PayloadAction<{ itemId: number; prebidId: number }>) => {},
      prepare: (itemId: number, prebidId: number) => ({ payload: { itemId, prebidId } }),
    },
    removeItemPrebidFailure: (_state, _action: PayloadAction<string>) => {},

    exitLiveAuction: (state) => {
      // * Bidder & deposit info are left behind to track winning status through srm
      return {
        ...INITIAL_STATE,
        bidder: state.bidder,
        winningItemDeposit: state.winningItemDeposit,
      };
    },

    setLiveDeposit: (state, action: PayloadAction<number>) => {
      state.winningItemDeposit = action.payload;
    },
  },
  extraReducers: {
    [userActions.logout.type]: () => INITIAL_STATE,
    [dashboardActions.getDashboardSuccess.type]: (
      state,
      action: PayloadAction<IResponse.DashboardResponseData>,
    ) => {
      const liveAuction = action?.payload?.events?.find((event) => event.simulcastLive === 1);
      if (liveAuction) {
        state.saleEventId = liveAuction?.id;
      }
    },
  },
});

export const liveAuctionReducer = liveAuctionSlice.reducer;

export const liveAuctionActions = liveAuctionSlice.actions;

import { IItem } from '.';

export interface VcastBidder {
  BidderBadge: string; // Connect
  CreditLimit: number; // Connect
  CreditUsed?: number; // Connect
  // custom values
  SendingBid?: boolean;
  PreviousBids: { [id: string]: number | undefined };
  CurrentBidID?: number;
  CurrentBidAmount?: number | boolean;
  HighestBidder: boolean;
  HasBids: boolean;
  HasOutbid?: number | boolean;
  LastBidAmount?: number;
  LastBidAccepted?: boolean;
}

export interface VcastUser {
  UserID: number;
  Customer?: {
    User: {
      Bidders: VcastBidder[];
    };
  };
  Bidders?: VcastBidder[]; // from user socket message
}

export interface SocketStatus {
  connected: boolean;
  client?: string;
  error?: string;
  attempt?: number;
  retries?: number;
}

export type SocketBidStatus =
  | 'Accepted'
  | 'PriorProposal'
  | 'OtherBid'
  | 'TooLow'
  | 'OutBid'
  | 'ToFloor'
  | 'CreditExceed'
  | 'AdjustDown'
  | 'FlaggedNoBuy'
  | 'ReppingItem'
  | 'Reset'
  | 'AnotherBidFirst';

export enum AUCTION_STATUS {
  'RUNNING' = 'RUNNING',
  'PAUSED' = 'PAUSED',
  'FINISHED' = 'FINISHED',
}

export type AuctionStatus = keyof typeof AUCTION_STATUS;

export type BidType = 'ONLINE' | 'SOLD' | 'IF' | 'NOSALE' | 'ASK' | 'FLOOR';

export interface VcastAuctionStats {
  CurrentBidAmount?: number;
  CurrentBidBadge?: string;
  CurrentBidType: BidType;
  CurrentBidID: number;
  CurrentItem?: VcastAuctionItem;
  CurrentItemID: number;
  ID: number;
  Increment: number;
  Running: number;
  Status: AuctionStatus;
  StatusMessage?: SocketBidStatus;
  EventStart: string;
  Streams: { EventID: string; StreamURL: string; Token: string }[];
  ServerTime: string;
  ItemSettings: { CreditFormula: string };
  Bids: {
    ID: number;
    Badge: string;
    Active: number;
    Amount: number;
  }[];
}

export interface VcastAuctionItem {
  ID: number;
  ExternalID: string;
  ItemNum: string; // item lot number
  StockNumber: string;
  Description: string;
  Deposit: number;
  Images: string[];
  CRIncidentType?: IItem.Vehicle.IncidentType;
  SalvageType: string;
  VIN: string;
  Engine: string;
  LicPlate: string;
  Color?: string;
  StockNum: string;
  YardLoc: string;
  Keys?: number;
  VehicleYear?: string;
  Make: string;
  Model: string;
  Settings: { CreditFormula: string };
  ConsignType: IItem.ConsignType;
  Trim: string;
}

export enum ACTION_TYPES {
  CONNECTED = 'Connect',
  USER = 'User',
  SET_FIELD = 'SetField',
  PROPOSE_BID_STATUS = 'ProposeBidStatus',
  EVENT_STATS = 'EventStats',
}

export interface VcastConnectedAction {
  type: ACTION_TYPES.CONNECTED;
  doc: {
    Customers: {
      ID: number;
      Events: {
        ID: number;
        ExtID: number;
      }[];
      User: {
        Bidders: VcastBidder[];
      };
    }[];
  };
}

export interface VcastUserAction {
  type: ACTION_TYPES.USER;
  doc: VcastUser;
}

export interface VcastSetFieldAction {
  type: ACTION_TYPES.SET_FIELD;
  doc: SetFileldMessage | SetFieldIncrement;
}

interface SetFileldMessage {
  Field: 'MESSAGE';
  BidID: number;
  Value: string;
}

interface SetFieldIncrement {
  Field: 'INCREMENT';
  BidID: number;
  Value: number;
}

export interface VcastProposeBidStatusAction {
  type: ACTION_TYPES.PROPOSE_BID_STATUS;
  sender: string;
  doc: {
    Amount: number;
    Badge: string; // stringify number
    Status: SocketBidStatus;
    ItemID: number;
  };
}

export type VcastEventStatsAction = {
  type: ACTION_TYPES.EVENT_STATS;
  doc: {
    ClerkUpdateUI: boolean;
    Stats: VcastAuctionStats;
  };
};

export type LiveAuctionVcastAction =
  | VcastConnectedAction
  | VcastUserAction
  | VcastSetFieldAction
  | VcastProposeBidStatusAction
  | VcastEventStatsAction;

import { useSelector as reduxUseSelector } from 'react-redux';
import { ApplicationSelectors } from '../application/selectors';
import { UserSelectors } from './selectors';

export const createUserHooks = (useSelector: typeof reduxUseSelector) => ({
  useUserResetPasswortStatus: () => useSelector(UserSelectors.isEmailSend),
  /**
   * Get the user account information
   */
  useUserInfo: () => useSelector(UserSelectors.user),

  /**
   * Get user account
   * @return account from the state only token still exist
   */
  useUserAccount: () => {
    const token = useSelector(UserSelectors.token);
    const account = useSelector(UserSelectors.account);
    return token ? account : null;
  },

  useUserToken: () => useSelector(UserSelectors.token),

  useUserBids: () => {
    const bids = useSelector(UserSelectors.bids);
    const timer = useSelector(ApplicationSelectors.timer);

    const futureBids =
      bids
        ?.filter((bid) => bid.endTime >= timer)
        .sort((bid, nextBid) => bid.endTime - nextBid.endTime) || [];

    const pastBids =
      bids
        ?.filter((bid) => bid.endTime < timer)
        .sort((bid, nextBid) => nextBid.endTime - bid.endTime) || [];

    const sortedBids = [...futureBids, ...pastBids];
    return sortedBids;
  },

  useUserTransactions: () => useSelector(UserSelectors.transactions),

  useIsResetEmailSend: () => useSelector(UserSelectors.isEmailSend),

  useIsRegistrationPassed: () => useSelector(UserSelectors.isRegistrationPassed),

  useWinningBids: () => {
    return useSelector(UserSelectors.winningBids) || [];
  },
});

import { delay, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';

import { userActions, appActions, webActions } from '../redux/actions';

function* loginUserError() {
  yield delay(50); // Fix for alert & modals conflict (iOS)
  yield put(
    appActions.showAlert({
      alertType: 'alert',
      title: i18n.t('titles:error'),
      message: i18n.t('errors:login_user_failure'),
      buttonText: 'OK',
    }),
  );
}

function* registerUserError(action: ReturnType<typeof userActions.registerUserFailure>) {
  const error = action.payload;
  let errorObj = null;
  if (error) {
    switch (error.code) {
      case 'ERR_DUPLICATE_USER_EMAIL':
        errorObj = {
          errorType: 'error',
          title: i18n.t('errors:email_exists'),
          message: i18n.t('errors:register_another_email'),
        };
        break;
      case 'ERR_DUPLICATE_USERNAME':
        errorObj = {
          errorType: 'error',
          title: i18n.t('errors:username_exists'),
          message: i18n.t('errors:register_another_username'),
        };
        break;
      case 'ERR_DUPLCATE_GOV_ID':
        errorObj = {
          errorType: 'error',
          title: i18n.t('errors:ic_number_exists'),
          message: i18n.t('errors:register_another_ic_number'),
        };
        break;
      default:
        errorObj = {
          errorType: 'error',
          title: i18n.t('errors:register_user_failure'),
          message: i18n.t('errors:contact_support'),
        };
    }
    yield put(webActions.setRegisterError(errorObj));
    yield delay(50);
    yield put(appActions.showAlert(errorObj));
  }
}

function* resetPasswordError() {
  yield delay(50); // Fix for alert & modals conflict (iOS)
  yield put(
    appActions.showAlert({
      alertType: 'alert',
      title: 'Error',
      message: i18n.t('errors:email_not_register'),
      buttonText: 'OK',
    }),
  );
}

export default function* errorsSaga() {
  yield takeLatest(userActions.loginUserFailure.type, loginUserError);
  yield takeLatest(userActions.registerUserFailure.type, registerUserError);
  yield takeLatest(userActions.resetPasswordFailure.type, resetPasswordError);
}

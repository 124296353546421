import moment from 'moment';
import { useSelector as reduxUseSelector } from 'react-redux';
import { RootState } from '..';
import { IAuction, IGeneral } from '../../models';
import { isAuctionRunning } from '../../models/helpers';
import { mapItemsToArray } from '../../utils/helpers';
import { MyPurchaseType } from '../../utils/constants';
import { MyPurchaseTypeType } from '../../utils/types';
import { ApplicationSelectors } from '../selectors';
import { DashboardSelectors } from './selectors';

const defaultFilter = {
  itemIds: [],
  selected: { year: [], make: [], model: [], location: [] },
  available: { year: [], make: [], model: [], location: [] },
};

export const createDashboardHooks = (useSelector: typeof reduxUseSelector) => ({
  useAuctions: () => {
    const auctionsMap = useSelector(DashboardSelectors.auctions);
    return mapItemsToArray(auctionsMap).sort((a, b) =>
      a.simulcastLive > b.simulcastLive ? -1 : 1,
    );
  },

  useAuction: (id?: number | null) => {
    const auctionsMap = useSelector(DashboardSelectors.auctions);
    return id && auctionsMap[id] ? auctionsMap[id] : null;
  },

  useRunningAuctions: () => {
    const timestamp = useSelector((state: RootState) => state.application.datetime);
    const auctionsMap = useSelector(DashboardSelectors.auctions);

    return mapItemsToArray(auctionsMap)
      .sort((a, b) => (a.simulcastLive > b.simulcastLive ? -1 : 1))
      .filter((auction) =>
        isAuctionRunning(
          auction.eventType,
          !!auction.simulcastLive,
          auction.startAt,
          auction.endAt,
          timestamp,
        ),
      );
  },

  useCounts: () => {
    return useSelector(DashboardSelectors.counts);
  },

  // To remove usage of the below 3 hooks to get counts in mobile app later, use the above hooks (useCounts) instead.
  useAuctionFavCount: (id: number) => {
    const auctionsMap = useSelector(DashboardSelectors.auctions);
    return auctionsMap[id]?.favsCt || 0;
  },

  useAuctionPrebidCount: (id: number) => {
    const auctionsMap = useSelector(DashboardSelectors.auctions);
    return auctionsMap[id]?.prebidsCt || 0;
  },

  useAuctionItemTotalCount: (id: number) => {
    const auctionsMap = useSelector(DashboardSelectors.auctions);
    return auctionsMap[id]?.itemsCt || 0;
  },

  useSortedAuctions: () => {
    const timestamp = useSelector(ApplicationSelectors.timer);
    const now = moment(timestamp);

    const auctionsMap = useSelector(DashboardSelectors.auctions);
    const auctions = mapItemsToArray(auctionsMap);

    const sortByStart = (a: IAuction.Auction, b: IAuction.Auction) =>
      a.startAt < b.startAt ? -1 : 1;

    const todayRunningLiveAuction: IAuction.Auction[] = [];
    const todayUpcomingLiveAuctions: IAuction.Auction[] = [];
    const runningAuctions: IAuction.Auction[] = [];
    const futureAuctions: IAuction.Auction[] = [];

    auctions.forEach((auction) => {
      const startAt = moment(auction.startAt);

      if (auction.eventType === IAuction.AUCTION_TYPES.LIVE && now.isSame(startAt, 'day')) {
        if (auction.simulcastLive) {
          todayRunningLiveAuction.push(auction);
        } else {
          todayUpcomingLiveAuctions.push(auction);
        }
      } else {
        if (now > startAt && now < moment(auction.endAt)) {
          runningAuctions.push(auction);
        } else if (now < startAt) {
          futureAuctions.push(auction);
        }
      }
    });

    const auctionsArray = [
      ...todayRunningLiveAuction.sort(sortByStart),
      ...todayUpcomingLiveAuctions.sort(sortByStart),
      ...runningAuctions.sort((a, b) => (a.endAt < b.endAt ? -1 : 1)),
      ...futureAuctions.sort(sortByStart),
    ];

    return auctionsArray;
  },

  useAuctionPagination: (auctionId: number) => {
    const auctionPagination = useSelector(DashboardSelectors.auctionPagination);
    return auctionPagination[auctionId] || { id: 0, page: 0, total: 0 };
  },

  useAuctionItemFilteredCount: (auctionId: number) => {
    const auctionPagination = useSelector(DashboardSelectors.auctionPagination);
    return auctionPagination[auctionId]?.total || 0;
  },

  useAuctionPage: (auctionId: number) => {
    const auctionPagination = useSelector(DashboardSelectors.auctionPagination);
    return auctionPagination[auctionId]?.page || 0;
  },

  usePurchasePage: () => {
    const purchasePagination = useSelector(DashboardSelectors.purchasePagination);
    return purchasePagination?.page || 0;
  },

  usePurchaseTotal: () => {
    const purchasePagination = useSelector(DashboardSelectors.purchasePagination);
    return purchasePagination?.total || 0;
  },

  usePurchasesAndOffersPagination: (purchaseType: MyPurchaseTypeType) => {
    const initialPagination: IGeneral.Pagination = { page: 0, total: 0 };
    const paginationSelectors = {
      [MyPurchaseType.unpaid]: useSelector(DashboardSelectors.unpaidPurchasePagination),
      [MyPurchaseType.paid]: useSelector(DashboardSelectors.paidPurchasePagination),
      [MyPurchaseType.offer]: initialPagination,
    };

    return paginationSelectors[purchaseType] || initialPagination;
  },

  useFavoritePage: () => {
    const favoritePagination = useSelector(DashboardSelectors.favoritePagination);
    return favoritePagination?.page || 0;
  },

  useSearchSuggestions: () => {
    const searchPagination = useSelector(DashboardSelectors.searchPagination);
    return searchPagination?.suggestions || [];
  },

  useSearchTotal: () => {
    const searchPagination = useSelector(DashboardSelectors.searchPagination);
    return searchPagination?.total || 0;
  },

  useSearchPage: () => {
    const searchPagination = useSelector(DashboardSelectors.searchPagination);
    return searchPagination?.page || 0;
  },

  /**
   * Get all the info related to search
   * @returns object { keyword, suggestions, total, page }
   */
  useSearch: () => {
    const searchPagination = useSelector(DashboardSelectors.searchPagination);
    return searchPagination;
  },

  useSearchKeyword: () => {
    const searchPagination = useSelector(DashboardSelectors.searchPagination);
    return searchPagination?.keyword || '';
  },

  useAuctionsFilter: () => {
    return useSelector(DashboardSelectors.auctionsFilter);
  },

  useAuctionFilter: (auctionId?: number) => {
    const filter = useSelector(DashboardSelectors.auctionFilter);
    return auctionId ? filter[auctionId] : defaultFilter;
  },

  useSearchFilter: () => {
    return useSelector(DashboardSelectors.searchFilter);
  },

  usePrebidFilter: () => {
    return useSelector(DashboardSelectors.prebidFilter);
  },

  useEbiddingFilter: () => {
    return useSelector(DashboardSelectors.ebiddingFilter);
  },

  usePurchaseFilter: () => {
    return useSelector(DashboardSelectors.purchaseFilter);
  },

  usePurchaseV2Filter: () => {
    return useSelector(DashboardSelectors.purchaseV2Filter);
  },

  useFavoriteFilter: () => {
    return useSelector(DashboardSelectors.favoriteFilter);
  },
});

import config from '../config/env';
import { delay, put, select, takeLatest, fork } from 'redux-saga/effects';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
import i18n from 'i18next';

import { appActions, ebidActions, webActions } from '../redux/actions';
import { api } from '../services/api';
import { UserSelectors } from '../redux/selectors';

export function* applicationTimerSaga() {
  const isTimerEnabled = config.EBID_TIMER || false;

  do {
    yield put(appActions.onTimerTick(Date.now()));
    yield put(ebidActions.pingSocket());
    yield put(ebidActions.updateSendingState());
    yield delay(1000);
  } while (isTimerEnabled);
}

export function* rehydrateStore() {
  const token: string = yield select(UserSelectors.token);
  token && api && api.setAuthHeader(token);

  yield put(webActions.clearSavedPath());
  yield fork(applicationTimerSaga);
}

function* setApplicationLanguage(action: ReturnType<typeof appActions.setApplicationLanguage>) {
  i18n.changeLanguage(action.payload);
}

export default function* applicationSaga() {
  yield takeLatest<RehydrateAction>(REHYDRATE, rehydrateStore);
  yield takeLatest(appActions.setApplicationLanguage.type, setApplicationLanguage);
}

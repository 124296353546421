import { RootState } from '..';
import { IAuction } from '../../models';
import { mapItemsToArray } from '../../utils/helpers';

export const DashboardSelectors = {
  auctions: (state: RootState) => state.dashboard.auctions,

  counts: (state: RootState) => state.dashboard.counts,

  auctionsFilter: (state: RootState) => state.dashboard.filters.auctions,

  auctionFilter: (state: RootState) => state.dashboard.filters.auction,

  searchFilter: (state: RootState) => state.dashboard.filters.search,

  prebidFilter: (state: RootState) => state.dashboard.filters.prebid,

  ebiddingFilter: (state: RootState) => state.dashboard.filters.ebidding,

  purchaseFilter: (state: RootState) => state.dashboard.filters.purchase,

  purchaseV2Filter: (state: RootState) => state.dashboard.filters.purchaseV2,

  favoriteFilter: (state: RootState) => state.dashboard.filters.favorite,

  pagination: (state: RootState) => state.dashboard.pagination,

  auctionPagination: (state: RootState) => state.dashboard.pagination.auction,

  purchasePagination: (state: RootState) => state.dashboard.pagination.purchase,

  unpaidPurchasePagination: (state: RootState) => state.dashboard.pagination.purchaseV2.unpaid,

  paidPurchasePagination: (state: RootState) => state.dashboard.pagination.purchaseV2.paid,

  favoritePagination: (state: RootState) => state.dashboard.pagination.favorite,

  searchPagination: (state: RootState) => state.dashboard.pagination.search,

  runningLiveAuction: (state: RootState) => {
    const auctions = mapItemsToArray(state.dashboard.auctions);
    return auctions.find(
      (auction) => auction.eventType === IAuction.AUCTION_TYPES.LIVE && auction.simulcastLive,
    );
  },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWeb } from '../../models';

export interface WebState {
  savedPath: string;
  headerLinks: {
    sell: IWeb.ILink[];
    services: IWeb.ILink[];
    aboutUs: IWeb.ILink[];
    more: IWeb.ILink[];
    buy: IWeb.ILink[];
  };
  registerError: IWeb.RegisterErrorMsg | null;
  contactUsForm: {
    status: null | 'success' | 'error' | 'reset';
    message?: string;
  };
}

const INITIAL_STATE: WebState = {
  savedPath: '/',
  headerLinks: {
    sell: [],
    services: [],
    aboutUs: [],
    more: [],
    buy: [],
  },
  registerError: null,
  contactUsForm: {
    status: null,
  },
};

const webSlice = createSlice({
  name: 'web',
  initialState: INITIAL_STATE,
  reducers: {
    setSavedPath: (state, action) => {
      state.savedPath = action.payload.path;
    },
    clearSavedPath: (state) => {
      state.savedPath = INITIAL_STATE.savedPath;
    },
    setHeaderLinks: (state, action: PayloadAction<IWeb.HeaderLinksData>) => {
      const { sellPages, aboutUsPages, morePages, buyPages, servicesPages } = action.payload;
      if (!sellPages || !aboutUsPages || !morePages || !buyPages || !servicesPages) {
        return;
      }
      state.headerLinks.sell = [...sellPages];
      state.headerLinks.services = [...servicesPages];
      state.headerLinks.aboutUs = [...aboutUsPages];
      state.headerLinks.more = [...morePages];
      state.headerLinks.buy = [...buyPages];
    },
    setRegisterError: (state, action) => {
      const error = action.payload;
      state.registerError = error;
    },
    clearRegisterError: (state) => {
      state.registerError = INITIAL_STATE.registerError;
    },

    submitMailerContactUs: {
      reducer: () => {},
      prepare: (form: IWeb.ContactUsForm, recaptcha: string) => ({
        payload: { ...form, recaptcha },
      }),
    },
    submitMailerContactUsSuccess: (state, action: PayloadAction<'success' | 'reset'>) => {
      state.contactUsForm = {
        status: action.payload,
      };
    },
    submitMailerContactUsFailure: (state, action: PayloadAction<string>) => {
      state.contactUsForm = {
        status: 'error',
        message: action.payload,
      };
    },

    submitMailerSubscribeNewsletter: {
      reducer: () => {},
      prepare: (form: IWeb.SubscribeForm, recaptcha: string) => ({
        payload: { ...form, recaptcha },
      }),
    },
    submitMailerSubscribeNewsletterSuccess: () => {},
    submitMailerSubscribeNewsletterFailure: () => {},
  },
});

export const webReducer = webSlice.reducer;

export const webActions = webSlice.actions;

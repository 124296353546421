import { SweetAlertIcon } from 'sweetalert2';

export interface MobileAppConfig {
  ams_host?: string;
  dashboardNotice?: string;
  dashboardUpcomingAuctionsCount: number;
  dashboardEbidsCount: number;
  dashboardWatchlistCount: number;
  itemsListingPerpage: number;
  minVersion: string;
  playStoreLink: string;
  appStoreLink: string;
  imageUrlPrefix?: string;
  appSearchUrl?: string;
  appSearchToken?: string;
}

export type AlertConfig =
  | {
      alertType: 'alert';
      title: string;
      message: string;
      cancelable?: boolean;
      buttons?: AlertButton[];
      buttonText?: string;
    }
  | {
      alertType: 'dropdown';
      messageType: SweetAlertIcon;
      title: string;
      message: string;
    }
  | {
      alertType: 'modal';
      messageType: ModalAlertMessageType;
      title: string;
      message: string;
      buttonText?: string;
      onClose: () => void;
    };

export interface AlertButton {
  text?: string | undefined;
  onPress?: ((value?: string) => void) | undefined;
  style?: 'default' | 'cancel' | 'destructive' | undefined;
}

export type Environment = 'production' | 'test';

export type ProcessEnvironment = 'production' | 'staging' | 'development';

export const ENV: { [key: string]: ProcessEnvironment } = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};

export interface SentryPayload {
  message: string | any;
  section?: string;
}

export interface FirebaseAnalyticsPayload {
  event: string;
  data: any;
}

export type Platform = 'mobile' | 'web';

export type ModalAlertMessageType = 'success' | 'error' | 'info';

import { ApiResponse } from 'apisauce';
import i18n from 'i18next';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import config from '../config/env';
import { IUser } from '../models';
import {
  appActions,
  dashboardActions,
  itemActions,
  userActions,
  webActions,
} from '../redux/actions';
import { UserSelectors } from '../redux/selectors';
import { decryptText } from '../redux/user/utils';
import { api } from '../services/api';
import {
  AccountResponse,
  AuthTokenResponse,
  GetMolPayConfigData,
  GetMolPayConfigResponse,
  RegisterResponse,
  ResetPasswordResponse,
  WinningItemsResponse,
} from '../services/api/response.types';
import { SUPPORT_PHONE } from '../utils/config';

function* loginUser(action: ReturnType<typeof userActions.loginUser>) {
  const { email, password, userSubscription, isFromRegister } = action.payload;

  yield put(userActions.getAuthToken(email, password));

  const userTokenRace: {
    success?: ReturnType<typeof userActions.getAuthTokenSuccess>;
    error?: ReturnType<typeof userActions.getAuthTokenFailure>;
  } = yield race({
    success: take(userActions.getAuthTokenSuccess.type),
    error: take(userActions.getAuthTokenFailure.type),
  });

  if (userTokenRace.error) {
    yield put(userActions.loginUserFailure('userTokenRace error'));
    return;
  }

  if (userSubscription) {
    yield call(api.uploadPrefs, userSubscription);
  }

  const accountResponse: AccountResponse = yield call(api.getAccount);

  if (accountResponse.ok && accountResponse.data) {
    const account = accountResponse.data;
    yield put(itemActions.getOffers());
    yield put(itemActions.getFavorites(1));
    yield put(dashboardActions.getDashboard());

    if (!isFromRegister && account.status === 'PENDING') {
      yield put(userActions.loginPendingUserFailure());

      yield delay(50);

      yield put(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Sorry!',
          message: i18n.t('errors:still_pending_approval', { phone: SUPPORT_PHONE }),
          buttonText: 'OK',
          cancelable: false,
        }),
      );

      return;
    } else {
      yield put(userActions.getUserAccountSuccess(account));
    }
  } else {
    yield put(userActions.loginUserFailure('userAccountRace error'));
    return;
  }

  yield put(userActions.loginUserSuccess());

  const token: string = yield select(UserSelectors.FCMToken);

  if (token) {
    // we bind the token to successful logged in user
    yield put(userActions.sendPushToken(token));
  }

  // When we go back after the first login, no notification until restart but
  // restart on iOS does not work, it make user unable to login
  if (!isFromRegister) {
    yield put(appActions.navigatorNavigate(false, 'home'));
  }
}

function* getAuthToken(action: ReturnType<typeof userActions.getAuthToken>) {
  const { email, password } = action.payload;
  const body = { email, password };

  const response: AuthTokenResponse = yield call(api.getAuthToken, body);
  if (response.ok && response.data) {
    api.setAuthHeader(response.data);
    yield put(userActions.getAuthTokenSuccess(response.data));
  } else if (
    response.status === 400 &&
    response.data?.code === 'ERR_INVALID_USER_STATUS_ACCOUNT_PENDING'
  ) {
    yield put(userActions.loginPendingUserFailure());

    yield delay(50); // Fix for alert and scene update conflict

    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: 'Sorry!',
        message: i18n.t('errors:still_pending_approval', { phone: SUPPORT_PHONE }),
        buttonText: 'OK',
        cancelable: false,
      }),
    );
    // return;
  } else if (!response.ok) {
    yield put(userActions.getAuthTokenFailure('getAuthToken error'));
  }
}

function* registerUser(action: ReturnType<typeof userActions.registerUser>) {
  const { fields, userSubscription, idCard, userImage } = action.payload;
  yield put(userActions.createUser(fields));

  yield put(webActions.clearRegisterError());

  const createUserRace: {
    success?: ReturnType<typeof userActions.createUserSuccess>;
    error?: ReturnType<typeof userActions.createUserFailure>;
  } = yield race({
    success: take(userActions.createUserSuccess.type),
    error: take(userActions.createUserFailure.type),
  });

  if (createUserRace.success) {
    const id = createUserRace.success.payload;

    if (idCard) {
      yield put(userActions.uploadIdCard(id, idCard));
    }

    if (userImage) {
      yield put(userActions.uploadUserImage(id, userImage));
    }

    yield put(userActions.createUserSuccess(id));

    yield put(appActions.navigatorNavigate(false, 'registration_success'));
  }

  if (createUserRace.error) {
    yield put(userActions.registerUserFailure(createUserRace.error.payload));
    return;
  }

  yield put(userActions.loginUser(fields.email, fields.password, userSubscription, true));

  const loginUserRace: {
    success?: ReturnType<typeof userActions.loginUserSuccess>;
    error?: ReturnType<typeof userActions.loginUserFailure>;
  } = yield race({
    success: take(userActions.loginUserSuccess.type),
    error: take(userActions.loginUserFailure.type),
  });

  if (loginUserRace.error) {
    yield put(userActions.loginUserFailure(loginUserRace.error.payload));
    return;
  }

  yield put(userActions.registerUserSuccess());
}

export function* getUserAccount() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const response: AccountResponse = yield call(api.getAccount);
  if (response.ok && response.data) {
    yield put(userActions.getUserAccountSuccess(response.data));
  } else {
    yield put(userActions.getUserAccountFailure('getUserAccount error'));

    // INFO: moved status checking to api to cover all apis
    // if (response.status === 403 || response.status === 401) {
    //   yield put(userActions.logout());
    // }
  }
}

export function* createUser(action: ReturnType<typeof userActions.createUser>) {
  const response: RegisterResponse = yield call(api.registerUser, action.payload);
  if (response.ok && response.data) {
    yield put(userActions.createUserSuccess(response.data.id));
  } else {
    yield put(userActions.createUserFailure(response.data));
  }
}

export function* changePassword() {
  // const response: ApiResponse<any> = yield call(api.changePassword);
  // if (response.ok && response.data) {
  //   yield put(userActions.createUserSuccess());
  // } else {
  //   yield put(userActions.createUserFailure(response.data || 'createUser error'));
  // }
}

export function* resetPassword(action: ReturnType<typeof userActions.resetPassword>) {
  const email = action.payload;

  const response: ResetPasswordResponse = yield call(api.resetPassword, email);
  if (response.ok) {
    yield put(userActions.resetPasswordSuccess());
  } else {
    yield put(userActions.resetPasswordFailure('resetPassword error'));
  }
}

export function* getBlockedDeposit() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const response: WinningItemsResponse = yield call(api.getEbidWinningItemsByBidder, account.id);
  if (response.ok && response.data) {
    yield put(userActions.getBlockedDepositSuccess(response.data.data));
  } else {
    yield put(userActions.getBlockedDepositFailure('getBlockedDeposit error'));
  }
}

function* logout() {
  // TODO: We must call /api/logout here so that backend can deactivate the notification token
  yield call(api.removeAuthHeader);
  yield put(dashboardActions.getDashboard());
}

function* resetPasswordEmail() {
  yield put(
    appActions.showAlert({
      alertType: 'alert',
      title: i18n.t('titles:reset_password'),
      message: i18n.t('infos:reset_pass_sent'),
      buttonText: 'OK',
      cancelable: false,
    }),
  );
}

export function* getTransactionsHistory() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }

  const response: ApiResponse<IUser.UserTransaction[], string> = yield call(
    api.getTransactionHistory,
  );

  if (response.ok && response.data) {
    yield put(userActions.getTransactionsHistorySuccess(response.data));
  } else if (!response.ok) {
    yield put(userActions.getTransactionsHistoryFailure('history error'));
  }
}

export function* getMolPayConfig(action: ReturnType<typeof userActions.getMolPayConfig>) {
  let { amount } = action.payload;

  const response: GetMolPayConfigResponse = yield call(api.getMolPayConfig, { amount });

  if (response.ok && response.data) {
    const cipherKey = config.MOLPAY_CIPHER_KEY;
    const molpayConfigObj: GetMolPayConfigData = JSON.parse(response.data);
    if (molpayConfigObj.mp_username) {
      molpayConfigObj.mp_username = decryptText(molpayConfigObj.mp_username, cipherKey);
    }
    if (molpayConfigObj.mp_password) {
      molpayConfigObj.mp_password = decryptText(molpayConfigObj.mp_password, cipherKey);
    }
    const molpayConfig = JSON.stringify(molpayConfigObj);
    yield put(userActions.getMolPayConfigSuccess(molpayConfig));
  } else if (!response.ok) {
    yield put(userActions.getMolPayConfigFailure('getAuthToken error'));
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.loginUser.type, loginUser);
  yield takeLatest(userActions.getUserAccount.type, getUserAccount);
  // TODO: To figure out if we really need the following line
  // yield takeLatest(LiveAuctionActionTypes.ADD_LIVE_LOT_PREBID_SUCCESS, getUserAccount);
  yield takeLatest(userActions.getAuthToken.type, getAuthToken);
  yield takeLatest(userActions.createUser.type, createUser);
  yield takeLatest(userActions.changePassword.type, changePassword);
  yield takeLatest(userActions.registerUser.type, registerUser);
  yield takeLatest(userActions.resetPassword.type, resetPassword);
  yield takeLatest(userActions.logout.type, logout);
  yield takeLatest(userActions.resetPassword.type, resetPasswordEmail);
  yield takeLatest(userActions.getBlockedDeposit.type, getBlockedDeposit);
  yield takeLatest(userActions.getTransactionsHistory.type, getTransactionsHistory);
  yield takeLatest(userActions.getMolPayConfig.type, getMolPayConfig);
}

import { all } from 'redux-saga/effects';
import {
  applicationSaga,
  dashboardSaga,
  errorsSaga,
  itemSaga,
  liveAuctionSaga,
  ebidAuctionSaga,
  userSaga,
} from '@pickles/shared/sagas';
import webSaga from './web';

export default function* rootSaga() {
  yield all([
    applicationSaga(),
    dashboardSaga(),
    errorsSaga(),
    itemSaga(),
    liveAuctionSaga(),
    ebidAuctionSaga(),
    userSaga(),
    webSaga(),
  ]);
}

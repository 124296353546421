import { useSelector as reduxUseSelector } from 'react-redux';
import { RootState } from '..';
import { LiveAuctionSelectors } from './selectors';

export const createLiveAuctionHooks = (useSelector: typeof reduxUseSelector) => ({
  useSaleEventId: () => useSelector(LiveAuctionSelectors.saleEventId),

  useCurrentItem: () => useSelector(LiveAuctionSelectors.currentItem),

  useBidder: () => useSelector(LiveAuctionSelectors.bidder),

  useAuctionStatus: () => useSelector(LiveAuctionSelectors.auctionStatus),

  useAgora: () => useSelector(LiveAuctionSelectors.agora),

  useConnectionStatus: () => useSelector(LiveAuctionSelectors.connectionStatus),

  useSufficientBidderBalance: (deposit: number = 0) =>
    useSelector((state: RootState) => {
      const bidder = LiveAuctionSelectors.bidder(state);
      let credit_amt = deposit;
      if (bidder?.CreditLimit) {
        const hasCredit = bidder?.CreditLimit - (bidder?.CreditUsed || 0) - credit_amt >= 0;
        return hasCredit;
      }
    }),

  useBidderAvailableBalance: () => useSelector(LiveAuctionSelectors.bidderBalance),
});

import { Action } from '@reduxjs/toolkit';
import {
  ActionPattern,
  call,
  cancel,
  fork,
  put,
  take,
  takeLatest,
  takeLeading,
  throttle,
} from 'redux-saga/effects';
import { dashboardActions } from '../redux/actions';
import { api } from '../services/api';
import {
  AuctionDetailsResponse,
  AuctionsResponse,
  DashboardResponse,
  QuerySuggestionResponse,
} from '../services/api/response.types';

export function* getDashboard() {
  const response: DashboardResponse = yield call(api.getDashboard);

  if (response.ok && response.data) {
    yield put(dashboardActions.getDashboardSuccess(response.data));
  } else {
    yield put(dashboardActions.getDashboardFailure('getDashboard error'));
  }
}

function* getAuctions(action: ReturnType<typeof dashboardActions.getAuctions>) {
  const params = action.payload;

  const response: AuctionsResponse = yield call(api.getAuctionFilter, params);

  if (response.ok && response.data) {
    yield put(dashboardActions.getAuctionsSuccess(response.data));
  } else {
    yield put(dashboardActions.getAuctionsFailure('getAuctions error'));
  }
}

function* getAuction(action: ReturnType<typeof dashboardActions.getAuction>) {
  const eventId = action.payload;

  const response: AuctionDetailsResponse = yield call(api.getAuctionDetails, eventId);

  if (response.ok && response.data) {
    yield put(dashboardActions.getAuctionSuccess(response.data));
  } else {
    yield put(dashboardActions.getAuctionFailure('getAuction error'));
  }
}

export function* getSearchSuggestions(
  action: ReturnType<typeof dashboardActions.getSearchSuggestions>,
) {
  const keyword = action.payload;

  const response: QuerySuggestionResponse = yield call(api.getAppSearchQuerySuggestion, keyword);

  if (response.ok && response.data && response.data.results?.documents) {
    const docs = response.data.results?.documents;
    const results = docs.map((doc) => {
      return doc.suggestion;
    });
    yield put(dashboardActions.getSearchSuggestionsSuccess(keyword, results));
  } else {
    yield put(dashboardActions.getSearchSuggestionsFailure('getSearchSuggestions failed'));
  }
}

/**
 * Take Latest effect function to check both pattern and action payload
 * Since API calls can be coming from multiple components for same event,
 * this function handle the one that is not in the running stage
 */
const takeLatestWithArgs = (
  patternOrChannel: ActionPattern<Action<any>> | undefined,
  saga: any,
  ...args: any[]
) =>
  fork(function* () {
    let tasks: any = {};

    while (true) {
      const action: any = yield take(patternOrChannel);

      if (!tasks[action.payload]) {
        tasks[action.payload] = yield fork(saga, ...args.concat(action));
      }

      for (const [key] of Object.entries(tasks)) {
        if (!tasks[key].isRunning()) {
          yield cancel(tasks[key]);
          delete tasks[key];
        }
      }
    }
  });

export default function* dashboardSaga() {
  yield takeLatest(dashboardActions.getDashboard.type, getDashboard);
  yield takeLeading(dashboardActions.getAuctions.type, getAuctions);
  yield takeLatest(dashboardActions.setAuctionsFilter.type, getAuctions);
  yield takeLatestWithArgs(dashboardActions.getAuction.type, getAuction);
  yield throttle(750, dashboardActions.getSearchSuggestions.type, getSearchSuggestions);
}

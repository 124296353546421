import { IEbid } from '../../models';

export const DEFAULT_INCREMENT = 100;

export const calculator = (ranges: IEbid.IncrementRange[]) => ({
  getIncrement(amount: number) {
    return ranges?.find((i) => amount >= i.rangeFrom && amount <= i.rangeTo);
  },
  increase(amount: number) {
    const range = this.getIncrement(amount);
    return amount + (range?.increment || DEFAULT_INCREMENT);
  },
  decrease(amount: number) {
    const range = ranges?.find(
      (i) => amount >= i.rangeFrom + i.increment && amount <= i.rangeTo + i.increment,
    );
    return amount - (range?.increment || DEFAULT_INCREMENT);
  },
});

import { IEbid, ILive } from '.';

export enum STATUS {
  LOST = 'LOST',
  OFFER = 'OFFER',
  WON = 'WON',
  LOTTED = 'LOTTED',
  WINNING = 'WINNING',
  OUTBID = 'OUTBID',
  LIVE = 'LIVE',
  UNKNOWN = 'UNKNOWN',
}

export type Status = keyof typeof STATUS;

export enum CLOSED_STATUS {
  LOST = 'LOST',
  OFFER = 'OFFER',
  WON = 'WON',
}

export enum WINNER_STATUS {
  WINNING = 'WINNING',
  WON = 'WON',
  OFFER = 'OFFER',
}

export enum BID_STATUS_WITHOUT_NAVIGATION {
  LOST = 'lost',
  OFFER = 'offer',
}

export const enum TYPES {
  LIVE = 'LIVE',
  EBID = 'EBID',
}

export type Types = keyof typeof TYPES;

export interface Bidding {
  status: Status | undefined;
  auctionStatus: IEbid.AuctionStatus | ILive.AuctionStatus;
  type: Types;
  bidsCount: number;
  currentBidAmount: number;
  nextBidAmount: number;
  lastBidAmount: number;
  maxBidAmount: number;
  isMaxBidActive: boolean;
  startTime: number;
  endTime: number;
  saleEventId: number;
  winnerId?: number | undefined;
  // Logic in saga, not from backend
  maxBidInput?: number;
  hasBids?: boolean; // TRUE when isWinning=true || isOutbid=true
  isWinning?: boolean; // TRUE when lastBidAmount > 0 && winnerId is userId Highest Bidder
  isOutbid?: boolean; // TRUE when lastBidAmount > 0 && isWinning=false
  isWon?: boolean; // isWinning=true + isEnded=true
  isLost?: boolean; // isOutbid=true + isEnded=true
  isStaged?: boolean; // TRUE when isRunning=false + isEnded=false (Auction yet to start)
  isRunning?: boolean; // TRUE when isStaged=false + isEnded=false (Auction is running)
  isEnded?: boolean; // TRUE when isStage=false + isRunning=false (Auction has ended)
  isOffer?: boolean; // TRUE when isWinning=true + isEnded=true + status=OFFER
  isOfferWinner?: boolean; // TRUE when status = OFFER and winner id = user id (ebid) or winner badge = user badge (live)
  isSold?: boolean; // TRUE when isWinning=true + isEnded=true + status=SOLD
  isNoSale?: boolean; // TRUE when isEnded=true + status=NOSALE
  // canUpdateMaxBid?: boolean; // TODO - Require to move 'amount' from EbidRunning component state into redux state, to be discuss
  sendingState?: {
    isSending?: boolean;
    sendingSince?: number;
    hasExceededDuration?: boolean;
  };

  // Currently only for EBid
  rejectedBid?: {
    isFirstBidRejected?: boolean; // TRUE when autobidActive=false && statusCode=100 && lastBidAmount=0
    rejectedBidAmount?: number | null;
  };

  // Currently apply to LiveAuction only
  isAsking?: boolean;
  userBadge?: string;
  winnerBadge?: string;
}

import { en } from './en';
import { ms } from './ms';
import { zh } from './zh';
import i18n, { ThirdPartyModule } from 'i18next';

export const createLanguageObject = (lib: ThirdPartyModule) => {
  i18n.use(lib).init({
    resources: { en, ms, zh },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
  return i18n;
};

import * as Sentry from '@sentry/nextjs';
import {
  Dedupe as DedupeIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';
import config from '../shared/config/env';

Sentry.init({
  enabled: config.SENTRY.ENABLED,
  dsn: config.SENTRY.CLIENT_DSN,
  environment: process.env.APP_ENV,
  debug: process.env.APP_ENV !== 'production',
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: [ 'error' ],
    }),
    new DedupeIntegration(),
  ],
  tracesSampleRate: 1.0,
});

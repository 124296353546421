import { RootState } from '..';

export const UserSelectors = {
  user: (state: RootState) => state.user,

  account: (state: RootState) => state.user.account,

  balance: (state: RootState) => state.user.account?.availableBalance || 0,

  FCMToken: (state: RootState) => state.user.FCMToken,

  accountStatus: (state: RootState) => state.user.account?.status,

  token: (state: RootState) => state.user.token,

  bids: (state: RootState) => state.user.bids,

  transactions: (state: RootState) => state.user.transactions,

  isEmailSend: (state: RootState) => state.user.isResetEmailSend,

  isRegistrationPassed: (state: RootState) => state.user.isRegistrationPassed,

  winningBids: (state: RootState) => state.user.winningBids,

  blockedItems: (state: RootState) => state.user.blocked,

  unblockedItems: (state: RootState) => state.user.unblocked,
};

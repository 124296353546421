import config from "../../shared/config/env";

export const FB_PIXEL_ID = config.FB_PIXEL_ID;

export const pageview = () => {
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
// export const event = (name, options = {}) => {
//   window.fbq('track', name, options)
// }

import { RootState } from '..';

export const WebSelectors = {
  headerLinks: (state: RootState) => state.web.headerLinks,

  registerError: (state: RootState) => state.web.registerError,

  contactUsForm: (state: RootState) => state.web.contactUsForm,

  savedPath: (state: RootState) => state.web.savedPath,
};

import { createSlice } from '@reduxjs/toolkit';
import {
  dashboardActions,
  itemActions,
  userActions,
  liveAuctionActions,
  ebidActions,
  webActions,
} from '../actions';

export interface LoaderState {
  login: boolean;
  register: boolean;
  create: boolean;
  watchlist: boolean;
  ebids: boolean;
  dashboard: boolean;
  item: boolean;
  items: boolean;
  attend: boolean;
  purchases: boolean;
  purchasesAndOffers: boolean;
  submitPayment: boolean;
  prebids: boolean;
  offers: boolean;
  socketConnecting: boolean;
  transactionHistory: boolean;
  account: boolean;
  searchResults: boolean;
  blockedDeposit: boolean;
  topUp: boolean;
  favorite: boolean;
  notes: boolean;
  submittingMailerContactUs: boolean;
  submittingMailerSubscribeNewsletter: boolean;
  [key: string]: boolean | undefined;
}

const INITIAL_STATE: LoaderState = {
  login: false,
  register: false,
  create: false,
  watchlist: false,
  ebids: false,
  dashboard: false,
  item: false,
  items: false,
  attend: false,
  purchases: false,
  purchasesAndOffers: false,
  submitPayment: false,
  prebids: false,
  offers: false,
  socketConnecting: false,
  transactionHistory: false,
  account: false,
  searchResults: false,
  blockedDeposit: false,
  topUp: false,
  favorite: false, // toggling item as favorite
  notes: false, // setting notes for item
  submittingMailerContactUs: false,
  submittingMailerSubscribeNewsletter: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [userActions.loginUser.type]: (state) => {
      state.login = true;
    },
    [userActions.loginUserSuccess.type]: (state) => {
      state.login = false;
    },
    [userActions.loginUserFailure.type]: (state) => {
      state.login = false;
    },
    [userActions.loginPendingUserFailure.type]: (state) => {
      state.login = false;
    },

    [userActions.registerUser.type]: (state) => {
      state.register = true;
    },
    [userActions.registerUserSuccess.type]: (state) => {
      state.register = false;
    },
    [userActions.registerUserFailure.type]: (state) => {
      state.register = false;
    },

    [userActions.createUser.type]: (state) => {
      state.create = true;
    },
    [userActions.createUserSuccess.type]: (state) => {
      state.create = false;
    },
    [userActions.createUserFailure.type]: (state) => {
      state.create = false;
    },

    [userActions.getTransactionsHistory.type]: (state) => {
      state.transactionHistory = true;
    },
    [userActions.getTransactionsHistorySuccess.type]: (state) => {
      state.transactionHistory = false;
    },
    [userActions.getTransactionsHistoryFailure.type]: (state) => {
      state.transactionHistory = false;
    },

    [userActions.getUserAccount.type]: (state) => {
      state.account = true;
    },
    [userActions.getUserAccountSuccess.type]: (state) => {
      state.account = false;
    },
    [userActions.getUserAccountFailure.type]: (state) => {
      state.account = false;
    },

    [userActions.getBlockedDeposit.type]: (state) => {
      state.blockedDeposit = true;
    },
    [userActions.getBlockedDepositSuccess.type]: (state) => {
      state.blockedDeposit = false;
    },
    [userActions.getBlockedDepositFailure.type]: (state) => {
      state.blockedDeposit = false;
    },

    [userActions.topUp.type]: (state) => {
      state.topUp = true;
    },
    [userActions.topUpSuccess.type]: (state) => {
      state.topUp = false;
    },
    [userActions.topUpFailure.type]: (state) => {
      state.topUp = false;
    },
    // User - group end

    // Item - group start
    [itemActions.getItem.type]: (state) => {
      state.item = true;
    },
    [itemActions.getItemSuccess.type]: (state) => {
      state.item = false;
    },
    [itemActions.getItemFailure.type]: (state) => {
      state.item = false;
    },

    [itemActions.getItems.type]: (state) => {
      state.items = true;
    },
    [itemActions.getItemsSuccess.type]: (state) => {
      state.items = false;
    },
    [itemActions.getItemsFailure.type]: (state) => {
      state.items = false;
    },

    [itemActions.setFavorite.type]: (state) => {
      state.favorite = true;
    },
    [itemActions.setFavoriteSuccess.type]: (state) => {
      state.favorite = false;
    },
    [itemActions.setFavoriteFailure.type]: (state) => {
      state.favorite = false;
    },

    [itemActions.setFavoritesNote.type]: (state) => {
      state.notes = true;
    },
    [itemActions.setFavoritesNoteSuccess.type]: (state) => {
      state.notes = false;
    },
    [itemActions.setFavoritesNoteFailure.type]: (state) => {
      state.notes = false;
    },

    [itemActions.getFavorites.type]: (state) => {
      state.watchlist = true;
    },
    [itemActions.getFavoritesSuccess.type]: (state) => {
      state.watchlist = false;
    },
    [itemActions.getFavoritesFailure.type]: (state) => {
      state.watchlist = false;
    },

    [itemActions.getPurchases.type]: (state) => {
      state.purchases = true;
    },
    [itemActions.getPurchasesSuccess.type]: (state) => {
      state.purchases = false;
    },
    [itemActions.getPurchasesFailure.type]: (state) => {
      state.purchases = false;
    },

    [itemActions.getPurchasesV2.type]: (state) => {
      state.purchasesAndOffers = true;
    },
    [itemActions.getPurchasesV2Success.type]: (state) => {
      state.purchasesAndOffers = false;
    },
    [itemActions.getPurchasesV2Failure.type]: (state) => {
      state.purchasesAndOffers = false;
    },

    [itemActions.submitPayment.type]: (state) => {
      state.submitPayment = true;
    },
    [itemActions.submitPaymentSuccess.type]: (state) => {
      state.submitPayment = false;
    },
    [itemActions.submitPaymentFailure.type]: (state) => {
      state.submitPayment = false;
    },

    [itemActions.getPrebids.type]: (state) => {
      state.prebids = true;
    },
    [itemActions.getPrebidsSuccess.type]: (state) => {
      state.prebids = false;
    },
    [itemActions.getPrebidsFailure.type]: (state) => {
      state.prebids = false;
    },

    [itemActions.getOffers.type]: (state) => {
      state.offers = true;
      state.purchasesAndOffers = true;
    },
    [itemActions.getOffersSuccess.type]: (state) => {
      state.offers = false;
      state.purchasesAndOffers = false;
    },
    [itemActions.getOffersFailure.type]: (state) => {
      state.offers = false;
      state.purchasesAndOffers = false;
    },

    [itemActions.getSearchResults.type]: (state) => {
      state.searchResults = true;
    },
    [itemActions.getSearchResultsSuccess.type]: (state) => {
      state.searchResults = false;
    },
    [itemActions.getSearchResultsFailure.type]: (state) => {
      state.searchResults = false;
    },
    [itemActions.getBidActivity.type]: (state) => {
      state.ebids = true;
    },
    [itemActions.getBidActivitySuccess.type]: (state) => {
      state.ebids = false;
    },
    [itemActions.getBidActivityFailure.type]: (state) => {
      state.ebids = false;
    },
    // Item - group end

    // Dashboard - group start
    [dashboardActions.getDashboard.type]: (state) => {
      state.dashboard = true;
    },
    [dashboardActions.getDashboardSuccess.type]: (state) => {
      state.dashboard = false;
    },
    [dashboardActions.getDashboardFailure.type]: (state) => {
      state.dashboard = false;
    },
    // Dashboard - group end

    // LiveAuction - group start
    [liveAuctionActions.attendLiveAuction.type]: (state) => {
      state.attend = true;
    },
    [liveAuctionActions.attendLiveAuctionSuccess.type]: (state) => {
      state.attend = false;
    },
    [liveAuctionActions.attendLiveAuctionFailure.type]: (state) => {
      state.attend = false;
    },
    // LiveAuction - group end

    // EbidAuction - group start
    [ebidActions.watchEbidAuctionSocket.type]: (state) => {
      state.socketConnecting = true;
    },
    [ebidActions.watchEbidAuctionSocketSuccess.type]: (state) => {
      state.socketConnecting = false;
    },
    [ebidActions.watchEbidAuctionSocketFailure.type]: (state) => {
      state.socketConnecting = false;
    },
    // EbidAuction - group end

    [webActions.submitMailerContactUs.type]: (state) => {
      state.submittingMailerContactUs = true;
    },
    [webActions.submitMailerContactUsSuccess.type]: (state) => {
      state.submittingMailerContactUs = false;
    },
    [webActions.submitMailerContactUsFailure.type]: (state) => {
      state.submittingMailerContactUs = false;
    },

    [webActions.submitMailerSubscribeNewsletter.type]: (state) => {
      state.submittingMailerSubscribeNewsletter = true;
    },
    [webActions.submitMailerSubscribeNewsletterSuccess.type]: (state) => {
      state.submittingMailerSubscribeNewsletter = false;
    },
    [webActions.submitMailerSubscribeNewsletterFailure.type]: (state) => {
      state.submittingMailerSubscribeNewsletter = false;
    },

    [userActions.logout.type]: () => INITIAL_STATE,
  },
});

export const loaderReducer = loaderSlice.reducer;

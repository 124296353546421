import { MobileAppConfig } from '../models/application.types';

export const SUPPORT_PHONE = '+60166999170';
export const SUPPORT_EMAIL = 'support@pickles.my';
export const AGORA_APP_TEST_ID = 'f7c49955f48a46bd807c3042a4769a97';
export const AGORA_APP_PRODUCTION_ID = 'c47d53f798cd4dd2bd1274f47dd0e53c';
export const ENV_PASSWORD = 'M@k3M0n3y!';

export const MAIN_ADDRESS = 'https://pickles-v2-test.env.ams.pickles.my/';

export const DYNAMIC_LINK_BASE = 'https://picklesasia1.page.link';
export const FIREBASE_API_KEY = 'AIzaSyDGBDickJJfND4ZzeETT6m27tHYTAQmvJ0';
export const ANDROID_PACKAGE_NAME = 'com.velocicast.picklesasia';
export const IOS_BUNDLE_ID = 'com.velocicast.picklesasia1';
export const APPSTORE_ID = '1389681781';
export const GOOGLEPLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.pickles.pickleslive';

export const getStaticLinks = (appConfig: MobileAppConfig) => {
  const AMS_HOST_DEFAULT = 'https://www.pickles.my';
  const AMS_HOST = appConfig?.ams_host || AMS_HOST_DEFAULT;

  return {
    SELL: `${AMS_HOST}/sell`,
    SERVICE: `${AMS_HOST}/add-on-services/`, // from old website
    ABOUT_US: `${AMS_HOST}/about-us`,
    FAQ: `${AMS_HOST}/FAQ`,
    HELP_AND_SUPPORT: `${AMS_HOST}/contact-us`,
    TERMS_AND_CONDITION: `${AMS_HOST}/app-terms`,
  };
};

export const getAuctionLink = (id: number) => {
  return `https://picklesasia1.page.link/auction${id}`;
};

export const getMapDirectionLink = (lat: number, lng: number) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
};

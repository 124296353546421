export interface IncrementRange {
  id: string;
  increment: number;
  rangeFrom: number;
  rangeTo: number;
}

export interface EbidAuctionAuth {
  correlationId: string;
  expiresAt: number;
  key: string;
  service: string;
  token: string;
  type: string;
}

export interface EbidAuctionState {
  increments?: IncrementRange[];
  status?: SocketStatus;
  bidsHistory: HistoryMap;
  totalBids: BidsCountMap;
  counts: CountsMap;
  blockDepositCache?: BlockDepositCache;
  socketId: SocketId;
  socketPingStart: SocketPingStart;
  socketLatency: SocketLatency;
  socketPingId: SocketPingId;
}

export interface BlockDepositCache {
  userId: number;
  itemId: number;
  timestamp: number;
}

export interface Item {
  id: number;
  auctionStatus: AUCTION_STATUS;
  autobidActive: boolean;
  bidCount: number;
  city: string;
  clientItemId: string;
  conditionType: string;
  currentBidAmt: number;
  depositAmt: number;
  description: string;
  endAt: Date;
  endTime: number;
  eventId: string;
  eventName: string;
  eventType: string;
  image: string;
  images: string[];
  lastBid: number;
  make: string;
  maxAutobid: number;
  minBalanceReq: number; // bid activity
  minStartingBidAmt: number;
  model: string;
  name: string;
  nextBidAmt: number;
  nextBidInput: number;
  odometer: number;
  odoUnits: string;
  saleMessage?: string; // bid activity
  saleStatus: string | undefined;
  startAt: Date;
  startTime: number;
  winnerId: string | null;
  year: string;
}

export interface ItemsMap {
  [id: number]: Item;
}
export interface HistoryMap {
  [id: number]: HistoryType[];
}
export interface BidsCountMap {
  [id: number]: number;
}

export enum AUCTION_STATUS {
  ENDED = 'ENDED',
  RUNNING = 'RUNNING',
  STAGED = 'STAGED',
  WITHDRAWN = 'WITHDRAWN',
}

export type AuctionStatus = keyof typeof AUCTION_STATUS;

export type SocketId = string;
export type SocketPingStart = number;
export type SocketLatency = number;
export type SocketPingId = number;

export interface SocketStatus {
  connected: boolean;
  client?: string;
  error?: string | null;
  attempt?: number;
  retries?: number;
}

export enum SOCKET_MESSAGE_TYPES {
  EVENT_BID = 'event_bid',
  USER_BID = 'user_bid',
  ITEM_BID = 'item_bid',
  USER_BALANCE = 'user_balance',
  USER_FAVORITE = 'user_favorite',
  USER_PREBID = 'user_prebid',
  SALE_STATUS = 'sale_status',
  USER_DEPOSIT = 'user_deposit',
  USER_BADGE = 'user_badge',
  SRM = 'srm',
  PRESENCE = 'presence',
}

export enum DEPENDENCY_CLIENTS {
  BFF = 'BFF',
  RTE = 'RTE',
}

export enum DEPENDENCY_STATES {
  ONLINE = 'online',
}
export interface SocketRelay {
  msgType: 'srm';
  message: any;
}

interface SocketBid {
  itemId: string;
  winnerId: string;
  bidCount: number;
  currentBid: number;
  nextBid: number;
  startTime: number;
  endTime: number;
  auctionStatus: AuctionStatus;
  _id: string;
}

export interface EventBid extends SocketBid {
  msgType: SOCKET_MESSAGE_TYPES.EVENT_BID;
}

export interface ItemBid extends SocketBid {
  msgType: SOCKET_MESSAGE_TYPES.ITEM_BID;
}

export interface UserBid extends SocketBid {
  msgType: SOCKET_MESSAGE_TYPES.USER_BID;
  userId: string;
  lastBid: number;
  maxAutobid: number;
  autobidActive: boolean;
  statusCode: number;
  saleEventId: number;
}

export interface UserBalance {
  msgType: SOCKET_MESSAGE_TYPES.USER_BALANCE;
  amount: number;
  blocked_amount: number;
  display_amount: number;
  user_id: number;
  blocked_amount_live: number;
  blocked_amount_ebid: number;
}
export interface UserFavorite {
  msgType: SOCKET_MESSAGE_TYPES.USER_FAVORITE;
  item_id: number;
  fav_id: number;
  user_id: number;
  favorite: boolean;
  sale_event_id: number;
  notes?: string;
}
export interface UserPrebid {
  msgType: SOCKET_MESSAGE_TYPES.USER_PREBID;
  item_id: number;
  prebid_id: number;
  prebid_amount: number;
  user_id: number;
  sale_event_id: number;
}
export interface SaleStatus {
  msgType: SOCKET_MESSAGE_TYPES.SALE_STATUS;
  item_id: number;
  amount: number;
  status: number; // sale_items.sale_status
  user_id: number;
  sale_event_id: number;
  badge: string;
}

export interface DependencyPresence {
  msgType?: null;
  type: SOCKET_MESSAGE_TYPES.PRESENCE;
  client: DEPENDENCY_CLIENTS;
  state: DEPENDENCY_STATES;
  _id: string;
}

export interface SocketEventBidAction {
  type: SOCKET_MESSAGE_TYPES.EVENT_BID;
  event: EventBid;
}

export interface SocketUserBidAction {
  type: SOCKET_MESSAGE_TYPES.USER_BID;
  event: UserBid;
}

export interface SocketItemBidAction {
  type: SOCKET_MESSAGE_TYPES.ITEM_BID;
  event: ItemBid;
}

export interface SocketUserBadgeAction {
  type: SOCKET_MESSAGE_TYPES.USER_BADGE;
  event: {
    msgType: string;
    id: number;
    sale_event_id: number;
    badge: number;
    user_id: number;
    active: boolean;
  };
}

export interface SocketUserBalanceAction {
  type: SOCKET_MESSAGE_TYPES.USER_BALANCE;
  event: any;
}
export interface SocketUserFavoriteAction {
  type: SOCKET_MESSAGE_TYPES.USER_FAVORITE;
  event: any;
}
export interface SocketUserPrebidAction {
  type: SOCKET_MESSAGE_TYPES.USER_PREBID;
  event: any;
}
export interface SocketSaleStatusAction {
  type: SOCKET_MESSAGE_TYPES.SALE_STATUS;
  event: any;
}

export interface SocketDependencyPresenceAction {
  type: SOCKET_MESSAGE_TYPES.PRESENCE;
  event: any;
}

export type SocketMessage =
  | UserBid
  | EventBid
  | ItemBid
  | UserBalance
  | UserFavorite
  | UserPrebid
  | SaleStatus
  | SocketRelay
  | DependencyPresence;

export type SocketAction =
  | SocketEventBidAction
  | SocketUserBidAction
  | SocketUserBalanceAction
  | SocketUserFavoriteAction
  | SocketUserPrebidAction
  | SocketSaleStatusAction
  | SocketItemBidAction
  | SocketUserBadgeAction
  | SocketDependencyPresenceAction;

export interface BidHistoryResponse {
  amtAccepted: number;
  amtPlaced: number;
  bidType: string;
  bidderFakeId: string;
  clientUserId: string;
  correlationId: string;
  id: string;
  isAccepted: boolean;
  isProcessed: boolean;
  timestamp: number;
  badge?: number;
  location?: string;
}

// Actual fields needed for bids history state, also because of limited fields returned from socket message,
// which are used to ensure bid history up-to-date instead of depending on /bid-history endpoint all the time
export interface HistoryType {
  itemId?: number;
  amtAccepted: number;
  timestamp: number;
  id: string;
  clientUserId: string;
}
export interface BidHistoryAction {
  data: BidHistoryResponse[];
  totalResults: number;
  itemId: number;
}

export interface SetAutobidAction {
  itemId: number;
  userId: number;
  bidAmount: number;
  eventId?: number;
}

export interface Counts {
  winning: number;
  outbid: number;
}

export interface CountsMap extends Counts {
  [saleEventId: number]: Counts;
}

export interface SubscribeEventBidAction {
  saleEventId: number;
}

export interface SubscribeItemBidAction {
  itemId: number;
}

export interface RelayStats {
  CurrentBidID: number;
  CurrentBidAmount?: number;
  CurrentBidBadge?: string;
  _userId?: number;
  _itemId?: number;
  _saleEventId?: number;
}

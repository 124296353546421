import { STRAPI_URL } from '@pickles/shared/config/env';
import qs from 'qs';
import { IMedia } from '../../util/types';

export const getFooter = async () => {
  const query = qs.stringify(
    {
      populate: {
        leftBlock: {
          populate: {
            logo: '*',
            social_links: {
              populate: '*',
            },
          },
        },
        linksBlock: {
          populate: '*',
        },
        rightBlock: '*',
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const res = await fetch(`${STRAPI_URL}/api/footer?${query}`);
  const data: FooterResponse = await res.json();
  return data;
};

export type FooterResponse = {
  data: {
    id: number;
    attributes: {
      leftBlock: {
        id: number;
        copyright: string;
        poweredBy: string;
        logo: IMedia;
        social_links: {
          data: IMedia[];
        };
        linksBlock: [
          {
            id: number;
            title: string;
            links: {
              data: [
                {
                  id: number;
                  attributes: {
                    label: string;
                    url: string;
                  };
                },
              ];
            };
          },
        ];
        rightBlock: {
          id: number;
          title: string;
          text: string;
        };
      };
    };
  };
};

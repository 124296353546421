import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../../utils/types';
import { IApp } from '../../models';

export interface ApplicationState {
  datetime: number;
  language?: Language;
  config?: IApp.MobileAppConfig;
  // environment - Indicator for API level Env (Prod API vs Test API)
  environment: IApp.Environment;
  // process_env - Indicator for Host/Process infra level environment (local dev, staging build, production build)
  processEnvironment: IApp.ProcessEnvironment;
  platform: IApp.Platform;
}

const INITIAL_STATE: ApplicationState = {
  datetime: Date.now(),
  language: undefined,
  config: undefined,
  environment: 'production',
  processEnvironment: 'development',
  platform: 'web',
};

const applicationSlice = createSlice({
  name: 'application',
  initialState: INITIAL_STATE,
  reducers: {
    onTimerTick: {
      reducer: (state, action: PayloadAction<number>) => {
        state.datetime = action.payload;
      },
      prepare: (payload: number) => ({ payload }),
    },

    setApplicationLanguage: {
      reducer: (state, action: PayloadAction<Language>) => {
        state.language = action.payload;
      },
      prepare: (payload: Language) => ({ payload }),
    },

    switchEnvironment: () => {},

    shareMessage: {
      reducer: (_state, _action: PayloadAction<string>) => {},
      prepare: (payload: string) => ({ payload }),
    },

    openResetPasswordForm: () => {},

    setApplicationPlatform: {
      reducer: (state, action: PayloadAction<IApp.Platform>) => {
        state.platform = action.payload;
      },
      prepare: (payload: IApp.Platform) => ({ payload }),
    },

    setApplicationEnvironment: {
      reducer: (state, action: PayloadAction<IApp.Environment>) => {
        state.environment = action.payload;
      },
      prepare: (payload: IApp.Environment) => ({ payload }),
    },

    setProcessEnvironment: {
      reducer: (state, action: PayloadAction<IApp.ProcessEnvironment>) => {
        state.processEnvironment = action.payload;
      },
      prepare: (payload: IApp.ProcessEnvironment) => ({ payload }),
    },

    showAlert: {
      reducer: (_state, _action: PayloadAction<IApp.AlertConfig>) => {},
      prepare: (payload: IApp.AlertConfig) => ({ payload }),
    },

    navigatorNavigate: {
      reducer: (_state, _action: PayloadAction<{ goBack?: boolean; route?: string }>) => {},
      prepare: (goBack?: boolean, route?: string) => ({
        payload: { goBack, route },
      }),
    },

    sendSentryMessage: {
      reducer: (_state, _action: PayloadAction<IApp.SentryPayload>) => {},
      prepare: (message: string | any, section?: string) => ({ payload: { message, section } }),
    },

    captureFirebaseAnalytics: {
      reducer: (_state, _action: PayloadAction<IApp.FirebaseAnalyticsPayload>) => {},
      prepare: (event: string, data: any) => ({ payload: { event, data } }),
    },

    getMobileAppConfig: () => {},
    getMobileAppConfigSuccess: {
      reducer: (state, action: PayloadAction<IApp.MobileAppConfig>) => {
        state.config = action.payload;
      },
      prepare: (payload: IApp.MobileAppConfig) => ({
        payload,
      }),
    },
    getMobileAppConfigFailure: {
      reducer: (_state, _action: PayloadAction<string>) => {},
      prepare: (payload: string) => ({ payload }),
    },
  },
});

export const applicationReducer = applicationSlice.reducer;

export const appActions = applicationSlice.actions;

import { createPersist } from '@pickles/shared/redux';
import { appActions } from '@pickles/shared/redux/actions';
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import storage from './storage';

// const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
//   key: 'root',
//   storage,
//   blacklist: ['dashboard', 'loader', 'inventory', 'live', 'ebid', 'item'],
//   timeout: 100,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const persistedReducer = createPersist(storage);

export type RootState = ReturnType<typeof persistedReducer>;

const sagaMiddleware = createSagaMiddleware();
const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(sagaMiddleware),
  devTools: {
    // hide actions in the devtool
    actionsBlacklist: [appActions.onTimerTick.type],
  },
});

sagaMiddleware
  .run(rootSaga)
  .toPromise()
  .catch();

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type AppAction = ReturnType<AppDispatch>;

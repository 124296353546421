/* eslint-disable */
// @ts-nocheck
export enum LOG_LEVEL {
  ASSERT,
  ERROR,
  WARN,
  INFO,
  DEBUG,
  VERBOSE,
}
export class Logger {
  asset: any | undefined;

  error: any | undefined;

  warn: any | undefined;

  info: any | undefined;

  debug: any | undefined;

  vebose: any | undefined;

  constructor(setLevel: LOG_LEVEL) {
    const levels = Object.values(LOG_LEVEL).filter((key) => isNaN(key));
    levels.forEach((level) => {
      const functionName = level.toLowerCase();
      this[functionName] = function() {};
      if (setLevel >= LOG_LEVEL[level]) {
        this[functionName] = console[functionName].bind(console);
      }
    });
  }

  dir(obj: object) {
    console.dir(ob);
    return this;
  }

  group(label: string | undefined) {
    console.groupCollapsed(label);
    return this;
  }

  groupExpand(label: string | undefined) {
    console.group(label);
    return this;
  }

  groupEnd() {
    console.groupEnd();
  }

  table(data: object | any[]) {
    console.table(data);
    return this;
  }

  trace(objects: object | undefined) {
    console.trace(objects);
    return this;
  }
}

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';

import { applicationReducer } from './application/slice';
import { liveAuctionReducer } from './live-auction/slice';
import { ebidAuctionReducer } from './ebid-auction/slice';
import { loaderReducer } from './loader/slice';
import { itemReducer } from './item/slice';
import { dashboardReducer } from './dashboard/slice';
import { userReducer } from './user/slice';
import { webReducer } from './web/slice';

export const createPersist = (storage: any) => {
  const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
    key: 'root',
    storage,
    blacklist: ['dashboard', 'loader', 'inventory', 'live', 'ebid', 'item'],
    timeout: 100,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  return persistedReducer;
};

export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  application: applicationReducer,
  user: userReducer,
  live: liveAuctionReducer,
  ebid: ebidAuctionReducer,
  loader: loaderReducer,
  web: webReducer,
  item: itemReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

import { call, put } from 'redux-saga/effects';
import { itemActions } from '../../redux/item/slice';
import { api } from '../../services/api';
import { GetPrebidsResponse } from '../../services/api/response.types';
import config from '../../config';

export function* getPrebids() {
  const prebidPagesize = config.PREBID_PAGE_SIZE;
  const params = {
    perpage: prebidPagesize,
  };
  const response: GetPrebidsResponse = yield call(api.getPrebids, params);

  if (response.ok && response.data) {
    yield put(itemActions.getPrebidsSuccess(response.data));
  } else {
    yield put(itemActions.getPrebidsFailure('getPrebids error'));
  }
}

import { IAuction, IBidding, IUser } from '.';

export function isAccountStatusActive(status: IUser.AccountStatus) {
  return status === IUser.ACCOUNT_STATUS.ACTIVE;
}

export function isAuctionTypeLive(eventType: IAuction.AuctionTypes) {
  return eventType === IAuction.AUCTION_TYPES.LIVE;
}
export function isAuctionTypeEbid(eventType: IAuction.AuctionTypes) {
  return eventType === IAuction.AUCTION_TYPES.STATIC;
}

export function isAuctionRunning(
  eventType: IAuction.AuctionTypes,
  isLiveStarted?: boolean,
  startAt?: any,
  endAt?: any,
  now?: number,
) {
  if (!now) {
    now = Date.now();
  }
  switch (eventType) {
    case IAuction.AUCTION_TYPES.LIVE:
      return !!isLiveStarted;
    case IAuction.AUCTION_TYPES.STATIC:
      return isEbidAuctionRunning(startAt, endAt, now);
    default:
      // console.error(eventType);
      return false;
  }
}

export function isEbidAuctionRunning(
  startAt: string | number,
  endAt: string | number,
  now: number = Date.now(),
) {
  return new Date(now).valueOf() >= new Date(startAt).valueOf() && now < new Date(endAt).valueOf();
}

export function isLiveAuctionRunning(simulcastLive?: IAuction.Auction['simulcastLive']) {
  return !!simulcastLive;
}

export function isCarDetailsNavigateable(bidStatus: any) {
  return !Object.values(IBidding.BID_STATUS_WITHOUT_NAVIGATION).includes(bidStatus);
}

import config from "../config";

export const enum AccountType {
  Dealer = 'DEALER',
  Broker = 'BROKER',
  Public = 'PUBLIC',
}

export const logoutErrors = ['AUTH_ERROR', 'TOKEN_EXPIRED_ERROR'];

export const allowedSendingBidDuration = 3000; //in milliseconds

export enum MyPurchaseType {
  unpaid = 'unpaid',
  paid = 'paid',
  offer = 'offer',
}

export const myPurchasesTabMenu = [
  { name: MyPurchaseType.unpaid, label: 'labels:unpaid', icon: 'unpaid', iconSize: 16 },
  { name: MyPurchaseType.offer, label: 'labels:offers', icon: 'offer', iconSize: 16 },
  { name: MyPurchaseType.paid, label: 'labels:paid', icon: 'paid', iconSize: 16 },
];

export const allowedNumberOfItemsPerSubmission = 5;

export const allowedPaymentReferenceLength = 50;

export const molpayDepositLimit = {
  max: config.MAX_DEPOSIT_AMOUNT || 5000, // MOLPAY allows max limit RM 5000
  min: config.MIN_DEPOSIT_AMOUNT || 2,
}

export const fpxDepositLimit = {
  max: config.FPX.MAX_DEPOSIT_AMOUNT || 10000,
  min: config.FPX.MIN_DEPOSIT_AMOUNT || 2,
}

export const fpxTransactionStatus = {
  SUCCESS: '00',
  PENDING: '99',
  TRANSACTION_PENDING: '09',
}

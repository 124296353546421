import CrazyEgg from "./CrazyEgg";
import TrengoChat from "./TrengoChat";
import FacebookPixel from "./FacebookPixel";
import GoogleAnalytics from "./GoogleAnalytics";

const Integrations = () => {
  return (
    <>
      <CrazyEgg />
      <TrengoChat />
      <FacebookPixel />
      <GoogleAnalytics />
    </>
  );
};

export default Integrations;

import i18n from 'i18next';

// Exporting this as a function because i18n translation is only initialized in mobile and web packages
export const FILTER_OBJECT_AUCTION_TYPE = () => [
  {
    key: 'LIVE',
    label: i18n.t('labels:live'),
    value: 'LIVE',
  },
  {
    key: 'STATIC',
    label: i18n.t('labels:e_bidding'),
    value: 'STATIC',
  },
];

export enum EVENT_CATEGORY {
  SALVAGE = 'SALVAGE',
  INDUSTRIAL = 'INDUSTRIAL',
  USED_CARS = 'USED_CARS',
  USED_MOTOR = 'USED_MOTOR',
}

export type EventCategory = keyof typeof EVENT_CATEGORY;

export const FILTER_OBJECT_AUCTION_CATEGORY = [
  {
    key: EVENT_CATEGORY.SALVAGE,
    label: 'Salvage',
    value: EVENT_CATEGORY.SALVAGE,
  },
  {
    key: EVENT_CATEGORY.INDUSTRIAL,
    label: 'Industrial',
    value: EVENT_CATEGORY.INDUSTRIAL,
  },
  {
    key: EVENT_CATEGORY.USED_CARS,
    label: 'Used Cars',
    value: EVENT_CATEGORY.USED_CARS,
  },
  {
    key: EVENT_CATEGORY.USED_MOTOR,
    label: 'Used Motorcycle',
    value: EVENT_CATEGORY.USED_MOTOR,
  },
];

export interface Pagination {
  page: number;
  total: number;
}

export interface AuctionPagination extends Pagination {
  id: number;
}

export interface SearchPagination extends Pagination {
  keyword: string;
  suggestions?: string[];
}

export interface ItemsListingFilter {
  year: string[];
  make: string[];
  model: string[];
  location?: string[];
  condition?: string[];
  category?: string[];
}

export enum PurchaseV2Filters {
  type = 'type',
  make = 'make',
  model = 'model',
  location = 'location',
}

export type PurchaseV2FiltersType = keyof typeof PurchaseV2Filters;

export enum ConsignorPaymentReconciliation {
  AUCTION = 'AUCTION',
  CONSIGNOR = 'CONSIGNOR',
}

export type ConsignorPaymentReconciliationType = keyof typeof ConsignorPaymentReconciliation;

export enum PaymentTerms {
  '3WD' = '03WD',
  '5D' = '05D',
  '05D' = '05WD',
  '07D' = '07D',
  '07WD' = '07WD',
  '10D' = '10D',
  '14D' = '14D',
  '30D' = '30D',
}

export type PaymentTermsType = keyof typeof PaymentTerms;

export enum InvoiceType {
  BALANCE = 'BALANCE',
  BUYER = 'BUYER',
  DEPOSITS = 'DEPOSITS',
}

export type InvoiceTypeType = keyof typeof InvoiceType;

export enum PaymentMethod {
  CASH = 'CASH',
  CHECK = 'CHECK',
  EFT = 'EFT',
  CREDITCARD = 'CREDITCARD',
  MOLPAY = 'MOLPAY',
}

export enum PaymentMode {
  B2B = 'b2b',
  B2C = 'b2c',
}

export type PaymentMethodType = keyof typeof PaymentMethod;

export interface FPXTransaction {
  orderId: string;
  externalOrderId: string;
  bankCode: string;
  transactionId: string;
  status: string;
  amount: number;
  createdAt: string;
  bankName: string;
}

export type PaymentGatewaySettings = {
  id: number;
  fpxEnabled: boolean;
  molpayEnabled: boolean;
};

export type PaymentMethods = {
  title: string;
  name: string;
  icons: StaticImageData[];
  disabled: boolean;
};

export interface FpxARForm {
  fpx_msgType: string;
  fpx_msgToken: string;
  fpx_sellerExId: string;
  fpx_sellerExOrderNo: string;
  fpx_sellerTxnTime: string;
  fpx_sellerOrderNo: string;
  fpx_sellerId: string;
  fpx_sellerBankCode: string;
  fpx_txnCurrency: string;
  fpx_txnAmount: string;
  fpx_buyerEmail: string;
  fpx_checkSum?: string;
  fpx_buyerName: string;
  fpx_buyerBankId: string;
  fpx_buyerBankBranch: string;
  fpx_buyerAccNo?: string;
  fpx_buyerId: string;
  fpx_makerName: string;
  fpx_buyerIban: string;
  fpx_productDesc: string;
  fpx_version: string;
  fpx_eaccountNum?: string;
  fpx_ebuyerID?: string;
}

export interface FPXTransaction {
  orderId: string;
  externalOrderId: string;
  bankCode: string;
  transactionId: string;
  status: string;
  amount: number;
  createdAt: string;
  bankName: string;
}

export interface OrderInfo {
  orderId: string;
  amount: number;
  userId: number;
  auctionId: number;
}

export interface Bank {
  id: number;
  code: string;
  name: string;
  displayName: string;
}

export interface PaymentIntent {
  externalOrderId: string;
  formData?: object | string;
}

export interface PaymentIntentItem {
  orderId: string;
  amount: number;
  externalOrderId: string;
  statusCode?: number;
}

import { RootState } from '..';
import _ from 'lodash';

export const LiveAuctionSelectors = {
  vcastEventId: (state: RootState) => state.live.vcastEventId,

  saleEventId: (state: RootState) => state.live.saleEventId,

  currentItem: (state: RootState) => state.live.currentItem,

  bidder: (state: RootState) => state.live.bidder,

  auctionStatus: (state: RootState) => state.live.auctionStatus,

  agora: (state: RootState) => state.live.agora,

  connectionStatus: (state: RootState) => state.live.connectionStatus,

  bidderBalance: (state: RootState) => {
    const bidder = state.live.bidder;
    const item = state.live.currentItem;

    if (bidder && item) {
      const creditRemain = bidder.CreditLimit - (bidder.CreditUsed || 0);
      return _.round(creditRemain, 2);
    } else {
      return 0;
    }
  },

  liveWinningItemDeposit: (state: RootState) => state.live.winningItemDeposit,
};

import { AccountType, MyPurchaseType } from './constants';

export type Language = 'en' | 'ms' | 'zh';

export interface ISignIn {
  email: string;
  password: string;
}

export interface ISignInForm extends ISignIn {
  rememberMe: boolean;
}

export interface ISignUp {
  email: string;
  password: string;
  repeatedPassword: string;
  fullName: string;
  phoneNumber: string;
  passport: string;
  avatarToUpload: Blob | null;
  idCardToUpload: Blob | null;
  accountType: AccountType | '';
  usedCarAuction: boolean;
  usedMotorAuction: boolean;
  salvageCarAuction: boolean;
  industrialAuctionList: boolean;
  agreeToTermsAndConditions: boolean;
}

export enum BidButtonType {
  NewItem,
  YouWin,
  Sold,
  SendingBid,
  TopUp,
  Offer,
  NoSale,
  Winning,
  BidNow,
  Outbid,
  UpdateMaxBid,
  SetMaxBid,
  Unknown,
  Lost,
  PendingResult,
  CheckAuctions,
}

export enum BidMessageType {
  PrebidAccepted,
  BeFirstBidder,
  StartBidding,
  SendingBid,
  SendingBidDelay,
  Winning,
  Outbid,
  LateBid,
  Offer,
  TopUp,
  PendingResult,
  YouWin,
  Lost,
  CheckAuctions,
  Unknown,
}

export type MyPurchaseTypeType = keyof typeof MyPurchaseType;

import { useSelector } from 'react-redux';
import {
  createApplicationHooks,
  createDashboardHooks,
  createEbidAuctionHooks,
  createLiveAuctionHooks,
  createLoaderHooks,
  createItemHooks,
  createUserHooks,
  createWebHooks,
} from '@pickles/shared/redux/hooks';

export const AppHooks = createApplicationHooks(useSelector);
export const DashboardHooks = createDashboardHooks(useSelector);
export const EbidAuctionHooks = createEbidAuctionHooks(useSelector);
export const LiveAuctionHooks = createLiveAuctionHooks(useSelector);
export const LoaderHooks = createLoaderHooks(useSelector);
export const ItemHooks = createItemHooks(useSelector);
export const UserHooks = createUserHooks(useSelector);
export const WebHooks = createWebHooks(useSelector);

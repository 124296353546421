/* eslint-disable require-yield */
import * as Sentry from '@sentry/nextjs';
import { call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { Account } from '@pickles/shared/models/user.types';
import { appActions, userActions, webActions } from '@pickles/shared/redux/actions';
import { UserSelectors } from '@pickles/shared/redux/selectors';
import { api } from '@pickles/shared/services/api';
import Config from '@pickles/shared/config/env';
import { i18n } from '../../pages/_app';
import md5 from 'md5';

import {
  ContactUsResponse,
  MobileAppConfigResponse,
  SubscribeResponse,
} from '@pickles/shared/services/api/response.types';
import colors from '@pickles/shared/utils/colors';
import Swal, { SweetAlertResult } from 'sweetalert2';

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const Alert = Swal.mixin({
  confirmButtonColor: colors.primary,
});

function* showAlert(action: ReturnType<typeof appActions.showAlert>) {
  const config = action.payload;

  if (!config) {
    return;
  }

  switch (config.alertType) {
    case 'dropdown': {
      Toast.fire({
        title: config.title,
        text: config.message,
        icon: config.messageType || 'info',
        iconColor: colors.primary,
      });
      break;
    }
    case 'alert':
    default: {
      Alert.fire({
        title: config.title,
        text: config.message,
        confirmButtonText: config.buttonText || 'OK',
      });
      break;
    }
  }
}

function captureFirebaseAnalytics(action: ReturnType<typeof appActions.captureFirebaseAnalytics>) {
  const { event, data } = action.payload;
  // TODO: Implement for web
}

function* sendSentryMessage(action: ReturnType<typeof appActions.sendSentryMessage>) {
  const { message, section } = action.payload;
  if (!section) {
    Sentry.captureMessage(message);
  } else {
    Sentry.captureException(message, { extra: { section } });
  }
}

export function* topUp(action: ReturnType<typeof userActions.topUp>) {
  if (!$) {
    yield put(userActions.topUpFailure(i18n.t('labels:failed_deposit')));
    return;
  }

  const account: Account = yield select(UserSelectors.account);
  const { amount, paymentMethod } = action.payload;

  yield put(userActions.getMolPayConfig(amount, process.env.NODE_ENV));

  const molPayConfigRace: {
    success: ReturnType<typeof userActions.getMolPayConfigSuccess>;
    error: ReturnType<typeof userActions.getMolPayConfigFailure>;
  } = yield race({
    success: take(userActions.getMolPayConfigSuccess.type),
    error: take(userActions.getMolPayConfigFailure.type),
  });

  if (molPayConfigRace.success) {
    const result = molPayConfigRace.success;
    const parsedConfig = JSON.parse(result.payload);
    parsedConfig.mp_bill_name = account.lastName || account.username;
    parsedConfig.mp_bill_description = 'Adding Funds';
    parsedConfig.mp_bill_email = account.email;
    parsedConfig.mp_bill_mobile = account.phone || '';

    const amountInNumberFormat = parseFloat(parsedConfig.mp_amount);
    const formattedAmount = amountInNumberFormat.toFixed(2);
    const vcode = md5(
      `${formattedAmount}${parsedConfig.mp_merchant_ID}${parsedConfig.mp_order_ID}${parsedConfig.mp_verification_key}`,
    );

    $('#molpay_btn')
      .attr('data-toggle', 'molpayseamless')
      .attr('data-mpsmerchantid', parsedConfig.mp_merchant_ID)
      .attr('data-mpschannel', paymentMethod)
      .attr('data-mpsamount', formattedAmount)
      .attr('data-mpsorderid', parsedConfig.mp_order_ID)
      .attr('data-mpsbill_name', parsedConfig.mp_bill_name)
      .attr('data-mpsbill_email', parsedConfig.mp_bill_email)
      .attr('data-mpsbill_mobile', parsedConfig.mp_bill_mobile)
      .attr('data-mpsbill_desc', parsedConfig.mp_bill_description)
      .attr('data-mpscurrency', parsedConfig.mp_currency)
      .attr('data-mpscountry', parsedConfig.mp_country)
      .attr('data-mpverification_key', parsedConfig.mp_verification_key)
      .attr('data-mpusername', parsedConfig.mp_username)
      .attr('data-mpsreturnurl', `${Config.HOST_URL}/profile`)
      .attr('data-mpsvcode', vcode)
      .trigger('click');
  } else {
    yield put(userActions.topUpFailure(i18n.t('labels:failed_deposit')));
  }
}

function* uploadIdCard(action: ReturnType<typeof userActions.uploadIdCard>) {
  const { id, image } = action.payload;

  const form = new FormData();
  form.append('type', 'GOVID');
  form.append('location', image);
  yield call(api.uploadFile, form, id);
}

function* uploadUserImage(action: ReturnType<typeof userActions.uploadUserImage>) {
  const { id, image } = action.payload;

  const form = new FormData();
  form.append('type', 'PROFPIC');
  form.append('location', image);
  yield call(api.uploadFile, form, id);
}

// TODO: Refactor this to getAppConfig for both Web and Mobile
function* getMobileAppConfig() {
  const response: MobileAppConfigResponse = yield call(api.getMobileAppConfig);
  if (response.ok && response.data) {
    yield put(appActions.getMobileAppConfigSuccess(response.data.cfg));
    // TODO: Override appSearchUrl & appSearchToken in state
  } else {
    yield put(appActions.getMobileAppConfigFailure('getMobileAppConfig error'));
  }
}

function* submitMailerContactUs(action: ReturnType<typeof webActions.submitMailerContactUs>) {
  const body = action.payload;
  const response: ContactUsResponse = yield call(api.submitMailer, body);
  yield delay(2000);

  if (response.ok) {
    yield put(webActions.submitMailerContactUsSuccess('success'));
    const result: SweetAlertResult = yield call([Alert, Alert.fire], {
      title: i18n.t('titles:success'),
      text: i18n.t('infos:message_sent'),
      confirmButtonText: i18n.t('buttons:back'),
    });
    if (result.isConfirmed || result.isDismissed) {
      yield put(webActions.submitMailerContactUsSuccess('reset'));
    }
  } else {
    const result: SweetAlertResult = yield call([Alert, Alert.fire], {
      title: i18n.t('titles:failure'),
      text: i18n.t('infos:message_sent_error'),
      confirmButtonText: i18n.t('buttons:back'),
    });
    if (result.isConfirmed || result.isDismissed) {
      yield put(webActions.submitMailerContactUsSuccess('reset'));
    }
    yield put(webActions.submitMailerContactUsFailure('submitContactUsFailure error'));
  }
}

function* submitMailerSubscribeNewsletter(
  action: ReturnType<typeof webActions.submitMailerSubscribeNewsletter>,
) {
  const body = action.payload;
  const response: SubscribeResponse = yield call(api.submitMailer, body);
  yield delay(2000);

  if (response.ok) {
    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: i18n.t('titles:success'),
        message: i18n.t('infos:message_subscribed'),
      }),
    );
    yield put(webActions.submitMailerSubscribeNewsletterSuccess());
  } else {
    const errorMessage = response.data;

    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: i18n.t('titles:failure'),
        message: errorMessage || i18n.t('infos:message_sent_error'),
      }),
    );
    yield put(webActions.submitMailerSubscribeNewsletterFailure());
  }
}

export default function* webSaga() {
  yield takeLatest(userActions.topUp.type, topUp);
  yield takeLatest(appActions.getMobileAppConfig.type, getMobileAppConfig);
  yield takeLatest(appActions.showAlert.type, showAlert);
  yield takeEvery(appActions.sendSentryMessage.type, sendSentryMessage);
  yield takeLatest(userActions.uploadIdCard.type, uploadIdCard);
  yield takeLatest(userActions.uploadUserImage.type, uploadUserImage);
  yield takeLatest(webActions.submitMailerContactUs.type, submitMailerContactUs);
  yield takeLatest(
    webActions.submitMailerSubscribeNewsletter.type,
    submitMailerSubscribeNewsletter,
  );
}

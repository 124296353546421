import { IAuction } from '../../models';

export const setComputed = (auction: IAuction.Auction) => {
  return {
    ...auction,
    isLive: auction.eventType === IAuction.AUCTION_TYPES.LIVE,
    isLiveStarted: auction.eventType === IAuction.AUCTION_TYPES.LIVE && !!auction.simulcastLive,
    isEbid: auction.eventType === IAuction.AUCTION_TYPES.STATIC,
  };
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RegisterRequestBody } from '../../services/api/request.types';
import { appActions } from '../application/slice';
import { IBidding, IItem, IUser } from '../../models';

export interface UserState {
  account: IUser.Account | null;
  token: string | null;
  transactions: IUser.UserTransaction[];
  isResetEmailSend: boolean;
  isRegistrationPassed: boolean;
  FCMToken?: string;
  isNotificationAvailable: boolean;
  winningBids: IItem.Item[];
  bids: IItem.EbiddingActivityItem[] | null;
  blocked: {
    liveItems: number[];
    ebidItems: number[];
  };
  unblocked: {
    liveItems: number[];
    ebidItems: number[];
  };
}

const INITIAL_STATE: UserState = {
  account: null,
  token: null,
  transactions: [],
  isResetEmailSend: false,
  isRegistrationPassed: false,
  FCMToken: undefined,
  isNotificationAvailable: false,

  bids: null,
  winningBids: [],
  blocked: {
    liveItems: [],
    ebidItems: [],
  },
  unblocked: {
    liveItems: [],
    ebidItems: [],
  },
};

type RegisterUser = {
  fields: RegisterRequestBody;
  userSubscription: IUser.UserSubscription;
  idCard: Blob | null;
  userImage: Blob | null;
};

type LoginUser = {
  email: string;
  password: string;
  userSubscription?: IUser.UserSubscription;
  isFromRegister?: boolean;
};

type TopUp = {
  amount: string;
  paymentMethod: string;
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    registerUser: {
      reducer: (_state, _action: PayloadAction<RegisterUser>) => {},
      prepare: (
        fields: RegisterRequestBody,
        userSubscription: IUser.UserSubscription,
        idCard: Blob | null,
        userImage: Blob | null,
      ) => ({
        payload: { fields, userSubscription, idCard, userImage },
      }),
    },
    registerUserSuccess: (state) => {
      state.isRegistrationPassed = true;
    },
    registerUserFailure: (state, _action: PayloadAction<string>) => {
      state.isRegistrationPassed = false;
    },

    loginUser: {
      reducer: (_state, _action: PayloadAction<LoginUser>) => {},
      prepare: (
        email: string,
        password: string,
        userSubscription?: IUser.UserSubscription,
        isFromRegister?: boolean,
      ) => ({
        payload: { email, password, userSubscription, isFromRegister },
      }),
    },
    loginUserSuccess: () => {},
    loginUserFailure: (_state, _action: PayloadAction<string>) => {},
    loginPendingUserFailure: () => {},

    getUserAccount: () => {},
    getUserAccountSuccess: {
      reducer: (state, action: PayloadAction<IUser.Account>) => {
        state.account = action.payload;
      },
      prepare: (payload: IUser.Account) => ({
        payload,
      }),
    },
    getUserAccountFailure: (_state, _action: PayloadAction<string>) => {},

    getAuthToken: {
      reducer: (_state, _action: PayloadAction<{ email: string; password: string }>) => {},
      prepare: (email: string, password: string) => ({ payload: { email, password } }),
    },
    getAuthTokenSuccess: {
      reducer: (state, action: PayloadAction<string>) => {
        state.token = action.payload;
      },
      prepare: (payload: string) => ({ payload }),
    },
    getAuthTokenFailure: (_state, _action: PayloadAction<string>) => {},

    createUser: {
      reducer: (_state, _action: PayloadAction<RegisterRequestBody>) => {},
      prepare: (fields: RegisterRequestBody) => ({ payload: fields }),
    },
    createUserSuccess: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (id: number) => ({ payload: id }),
    },
    createUserFailure: (_state, _action: PayloadAction<string>) => {},

    changePassword: {
      reducer: (_state, _action: PayloadAction<{ oldPassword: string; newPassword: string }>) => {},
      prepare: (oldPassword: string, newPassword: string) => ({
        payload: { oldPassword, newPassword },
      }),
    },
    changePasswordSuccess: () => {},
    changePasswordFailure: (_state, _action: PayloadAction<string>) => {},

    resetPassword: {
      reducer: (_state, _action: PayloadAction<string>) => {},
      prepare: (email: string) => ({ payload: email }),
    },
    resetPasswordSuccess: (state) => {
      state.isResetEmailSend = true;
    },
    resetPasswordFailure: (_state, _action: PayloadAction<string>) => {},

    getTransactionsHistory: () => {},
    getTransactionsHistorySuccess: {
      reducer: (state, action: PayloadAction<IUser.UserTransaction[]>) => {
        state.transactions = action.payload;
      },
      prepare: (transactions) => ({ payload: transactions }),
    },
    getTransactionsHistoryFailure: (_state, _action: PayloadAction<string>) => {},

    topUp: {
      reducer: (_state, _action: PayloadAction<TopUp>) => {},
      prepare: (amount: string, paymentMethod: string) => ({ payload: { amount, paymentMethod } }),
    },
    topUpSuccess: () => {},
    topUpFailure: (_state, _action: PayloadAction<string>) => {},

    getMolPayConfig: {
      reducer: (_state, _action: PayloadAction<{ amount: string }>) => {},
      prepare: (amount: string) => ({ payload: { amount } }),
    },
    getMolPayConfigSuccess: {
      reducer: (_state, _action: PayloadAction<string>) => {},
      prepare: (config: string) => ({ payload: config }),
    },
    getMolPayConfigFailure: (_state, _action: PayloadAction<string>) => {},

    getStart: {
      reducer: (state) => {
        state.isRegistrationPassed = false;
      },
      prepare: (): any => {},
    },

    logout: () => INITIAL_STATE,

    setNotificationStatus: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isNotificationAvailable = action.payload;
      },
      prepare: (status: boolean) => ({ payload: status }),
    },

    uploadIdCard: {
      reducer: (_state, _action: PayloadAction<{ id: number; image: Blob }>) => {},
      prepare: (id: number, image: Blob) => ({ payload: { id, image } }),
    },

    uploadUserImage: {
      reducer: (_state, _action: PayloadAction<{ id: number; image: Blob }>) => {},
      prepare: (id: number, image: Blob) => ({ payload: { id, image } }),
    },

    // 👇 move to item or ebid
    getBlockedDeposit: () => {},
    getBlockedDepositSuccess: {
      reducer: (state, action: PayloadAction<IItem.Item[]>) => {
        state.winningBids = action.payload;
      },
      prepare: (data: IItem.Item[]) => ({ payload: data }),
    },
    getBlockedDepositFailure: (_state, _action: PayloadAction<string>) => {},

    setFCMToken: (state, action: PayloadAction<string>) => {
      state.FCMToken = action.payload;
    },

    sendPushToken: (_state, _action: PayloadAction<string>) => {},

    updateBlockItem: {
      // TODO: Please refactor this!
      reducer: (state, action: PayloadAction<IUser.UpdateBlockItem>) => {
        const { type, biddingType, itemId } = action.payload;
        if (!state.account) {
          return;
        }
        if (!state.blocked) {
          state.blocked = {
            liveItems: [],
            ebidItems: [],
          };
        }
        if (!state.unblocked) {
          state.unblocked = {
            liveItems: [],
            ebidItems: [],
          };
        }
        if (type === 'blocked') {
          if (biddingType === IBidding.TYPES.LIVE && itemId) {
            state.blocked.liveItems.push(itemId);
            state.unblocked.liveItems = state.unblocked.liveItems.filter(
              (id: number) => id !== itemId,
            );
          } else if (biddingType === IBidding.TYPES.EBID && itemId) {
            state.blocked.ebidItems.push(itemId);
            state.unblocked.ebidItems = state.unblocked.ebidItems.filter(
              (id: number) => id !== itemId,
            );
          }
        } else if (type === 'unblocked') {
          if (biddingType === IBidding.TYPES.LIVE && itemId) {
            state.unblocked.liveItems.push(itemId);
            state.blocked.liveItems = state.unblocked.liveItems.filter(
              (id: number) => id !== itemId,
            );
          } else if (biddingType === IBidding.TYPES.EBID && itemId) {
            state.unblocked.ebidItems.push(itemId);
            state.blocked.ebidItems = state.unblocked.ebidItems.filter(
              (id: number) => id !== itemId,
            );
          }
        }
      },
      prepare: (payload: IUser.UpdateBlockItem) => ({ payload }),
    },

    checkBalance: {
      reducer: () => {},
      prepare: (soldItemType: 'LIVE' | 'EBID') => ({ payload: soldItemType }),
    },
  },
  extraReducers: {
    [appActions.openResetPasswordForm.type]: (state) => {
      state.isResetEmailSend = false;
    },
    // FIXME: Create slice
    // [EbidAuctionSocketActionTypes.USER_BID]: (state, { event }) => {
    //   const bid: Partial<EbiddingActivityItem> = {
    //     clientItemId: event.itemId,
    //     currentBidAmt: event.currentBid,
    //     startTime: event.startTime,
    //     endTime: event.endTime,
    //     auctionStatus: event.auctionStatus,
    //     bidCount: event.bidCount,
    //     winnerId: event.winnerId,
    //   };

    //   const bids = state.bids?.map((i) => (i.clientItemId === event.itemId ? { ...i, ...bid } : i));
    //   state.bids = bids || null;
    // },
  },
});

export const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;

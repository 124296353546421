import { RootState } from '..';

export const EbidAuctionSelectors = {
  increments: (state: RootState) => state.ebid.increments,

  status: (state: RootState) => state.ebid.status,

  // TODO: to remove after updating usages
  items: (state: RootState) => state.ebid.items,

  history: (state: RootState) => state.ebid.bidsHistory,

  totalBids: (state: RootState) => state.ebid.totalBids,

  counts: (state: RootState) => state.ebid.counts,

  socketId: (state: RootState) => state.ebid.socketId,

  socketPingStart: (state: RootState) => state.ebid.socketPingStart,

  socketPingId: (state: RootState) => state.ebid.socketPingId,

  socketLatency: (state: RootState) => state.ebid.socketLatency,
};

import { IBidding, IEbid } from ".";

export type BuyerType = 'PUBLIC' | 'PRIVATE' | string; // TODO Extend

export type AccountStatus = 'ACTIVE' | 'PENDING'; // TODO: Extend

export enum ACCOUNT_STATUS {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export type SalvageType = 'PARTS' | 'WRECK';

export type Account = {
  id: number;
  firstName?: string;
  lastName?: string;
  username: string;
  email: string;
  phone?: string;
  language?: string;
  govId: string;
  status: AccountStatus;
  company?: string;
  icLocation?: string;
  availableBalance: number;
  blockedAmount: number;
  depositBalance: number;
  balanceUpdatedAt: string;
  profpicUrl?: string;
  rtc: {
    url: string;
    token: string;
  };
};

export type AuthToken = string;

export type UserTransaction = {
  id: number;
  docId: string;
  dateCreated: Date;
  docType: string;
  payType: string;
  amount: string;
};

export enum ACCOUNT_TYPE {
  DEALER = 'DEALER',
  BROKER = 'BROKER',
  PUBLIC = 'PUBLIC',
}

export type UserSubscription = {
  WKLY_USEDCAR_LIST: boolean;
  USEDMOTOR_LIST: boolean;
  WKLY_SALVAGE_LIST: boolean;
  INDUSTRIAL_LIST: boolean;
};

export enum UserTransactionType {
  REFUND = 'voucher/',
  PAYMENT = 'receipt/',
  DEPOSIT = 'receipt/',
  INVOICE = 'invoice/',
  ITEM_DEPOSITS = 'invoice/',
  ITEM_BUYER = 'invoice/',
}

export type EventDetails = {
  id: number;
  name: string;
  description?: string;
  auctionStatus: IEbid.AuctionStatus;
  image: string;
  lotNum: string;
  city: string;
  startTime: number;
  endTime: number;
  odometer?: number;
  odoUnits: string;
  salvageType: SalvageType;
  currentBidAmt: number;
};

export interface ImagePickerResponse {
  uri?: string;
  type?: string;
  fileName?: string;
  path?: string;
}

export interface UpdateBlockItem {
  type: 'blocked' | 'unblocked';
  biddingType: IBidding.Types;
  itemId: number;
}
